import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class KurirService {
  itemsRef: AngularFirestoreCollection<KurirModel>;
  items: Observable<KurirModel[]>;
  mainDB = environment.fDb;
  private _unsubscribeAll: Subject<any>;
  constructor(
    private afs: AngularFirestore) {
    this.itemsRef = this.afs.collection<KurirModel>(this.mainDB + 'users', ref => ref.where('level', '==', 'driver'));
  }

  getItems(): Observable<KurirModel[]> {
    return this.items = this.itemsRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as KurirModel;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  // addItem(data): Promise<boolean> {
  //   return this.itemsRef.add({ 'nama': data }).then(() => {
  //     return true;
  //   }).catch((e) => {
  //     return false;
  //   });
  // }

  approve(id): Promise<boolean> {
    return this.itemsRef.doc(id).update({ 'active': true }).then(() => {
      return true;
    }).catch((e) => {
      return false;
    });
  }

  block(id): Promise<boolean> {
    return this.itemsRef.doc(id).update({ 'active': false }).then(() => {
      return true;
    }).catch((e) => {
      return false;
    });
  }

  deleteItem(id): Promise<boolean> {
    return this.itemsRef.doc(id).delete().then(() => {
      return false;
    }).catch((e) => {
      return false;
    });
  }
  destroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

export interface KurirModel {
  id?: string,
  uid?: string,
  email?: string,
  nama?: string,
  phone?: string,
  photoUrl?: string,
  active?: boolean,
  level?: string,
  address?: string,
  pushToken?: string,
  lastOnline?: Date,
  latitude?: string,
  longitude?: string,
}

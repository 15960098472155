export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'CONTENT': {
                'TITLE': 'Kategori Produk',
                // 'BADGE': '25'
            },
            'SLIDE': {
                'TITLE': 'Slide',
                // 'BADGE': '25'
            },
            'DAFTARMENU': {
                'TITLE': 'Kategori Konten',
                // 'BADGE': '25'
            },
            'ORDER': {
                'TITLE': 'Order',
                'BADGE': '0'
            },
            'RORDER': {
                'TITLE': 'Reseller Order',
                'BADGE': '25'
            },
            'LISTKURIR': {
                'TITLE': 'Kurir',
                'BADGE': '25'
            },
            'RESELLER': {
                'TITLE': 'Reseller',
                'BADGE': '25'
            },
            'SUPPLIER': {
                'TITLE': 'Seller',
                'BADGE': '25'
            },
            'KONSUMEN': {
                'TITLE': 'Pelanggan',
                'BADGE': '25'
            },
            'BLOG': {
                'TITLE': 'Blog',
                'BADGE': '25'
            },
            'NOTIF': {
                'TITLE': 'Push Notifikasi',
                'BADGE': '25'
            },
            'LAPORAN': {
                'TITLE': 'Laporan',
                'BADGE': '25'
            },
            'PENGATURAN': {
                'TITLE': 'Pengaturan',
                'BADGE': '25'
            },
            'PENGATURAN1': {
                'TITLE': 'Pengaturan',
                'BADGE': '25'
            },
            'FAQ': {
                'TITLE': 'FAQ',
                'BADGE': '25'
            },
            'MASTERSATUAN': {
                'TITLE': 'Master Satuan',
                'BADGE': '25'
            },
            'DAFTARHARGA': {
                'TITLE': 'Daftar Harga',
                'BADGE': '25'
            },
            'KELUAR': {
                'TITLE': 'Keluar',
                'BADGE': '25'
            }
        }
    }
};

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { from, Observable, throwError, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { map, catchError, retry } from 'rxjs/operators';
import { iAlamat } from '../main/supplier/supplier-model/supplier-model';
import { result } from 'lodash';
import { promise } from 'protractor';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  BaseURL = environment.BaseUrl;
  rajaApiURL = environment.rajaApi;
  rajaApiToken = environment.apiToken;
  headersFcm = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': environment.serverKey
  });
  headersHttp = new HttpHeaders({
    'Accept': 'text/html, application/xhtml+xml, */*',
    'Content-Type': 'application/x-www-form-urlencoded',
    "Authorization": JSON.parse(localStorage.getItem('TOKEN')),
  });

  options = {
    headers: this.headersHttp
  };
  constructor(
    private http: HttpClient
  ) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }

  confirmToken(token: any): Observable<any> {
    const httpOptions = {
      headers: this.headersFcm
    };

    return this.http.post(this.BaseURL, token, httpOptions).pipe(
      catchError(this.handleError('tokenFcm', token))
    );
  }

  notif_update(notif: any): Observable<any> {
    let options = {
      headers: this.headersFcm
    };

    return this.http.put(this.BaseURL + 'Notif/notif', JSON.stringify(notif), options)
      .pipe(map(response => {
        return response;
      }));
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  getAPitoken(): Observable<any> {
    return this.http.get<Page<any>>(`${this.rajaApiToken}`)
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }
  getApiData(token: string, url: string): Observable<any> {
    return this.http.get<Page<any>>(`${this.rajaApiURL + 'MeP7c5ne' + token + url}`)
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }
}
export interface Page<T> {
  status: number,
  message: string,
  data: T[]
}
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Blog, SubCategory } from './model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  itemsRef: AngularFireList<Blog>;
  items: Observable<Blog[]>;
  blogRet: AngularFireList<SubCategory>;
  itemBlog: Observable<SubCategory[]>;
  mainDB = environment.mainDb;
  constructor(
    public storage: AngularFireStorage,
    private db: AngularFireDatabase) {
    this.itemsRef = db.list(this.mainDB + 'blog');
    this.items = this.itemsRef.snapshotChanges()
      .pipe(map(change => {
        return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      }))
  }

  getItems(): Observable<any> {
    return this.items
  }

  getCat(): Observable<SubCategory[]> {
    this.blogRet = this.db.list(this.mainDB + 'blogKategori');
    return this.itemBlog = this.blogRet.snapshotChanges()
      .pipe(map(change => {
        return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      }))
  }

  async delete(item: Blog) {
    await this.db.database.ref(this.mainDB + 'blog/' + item.id).remove();
    return true;
  }

  async simpanBlog(data: Blog): Promise<boolean> {
    let hasilPanduan: boolean;
    const panduan = this.db.database.ref(this.mainDB + 'blog/' + new Date().getTime());
    const dataSimpan: Blog = ({
      judul: data.judul,
      kategori: data.kategori,
      isi: data.isi,
    });
    await panduan.set(dataSimpan).then((res) => {
      return hasilPanduan = true;
    }).catch((er) => {
      return hasilPanduan = false;
    })
    return hasilPanduan;
  }
  async updateBlog(data: Blog): Promise<boolean> {
    let hasilPanduanEdit: boolean;
    const panduan = this.db.database.ref(this.mainDB + 'blog/' + data.id);
    const dataSimpan: Blog = ({
      judul: data.judul,
      kategori: data.kategori,
      isi: data.isi,
    });
    await panduan.set(dataSimpan).then((res) => {
      return hasilPanduanEdit = true;
    }).catch((er) => {
      return hasilPanduanEdit = false;
    })
    return hasilPanduanEdit;
  }

  async simpanCat(data: SubCategory[]): Promise<boolean> {
    let hasil: boolean;
    let ref = this.db.database.ref(this.mainDB + 'blogKategori/');
    await ref
      .set(data).then((res) => {
        hasil = true;
        return hasil;
      })
      .catch(
        (error: any) => {
          hasil = false;
          return hasil;
          // console.dir(supplierItem);
        });
    return hasil;
  }
}

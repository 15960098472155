import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
//import { DOCUMENT } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { FormControl, Validators, Form, NgForm } from '@angular/forms';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
declare var jQuery: any;
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ApiService } from 'app/services/api.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { environment } from 'environments/environment';

@Component({
  selector: 'laporan',
  templateUrl: './laporan.component.html',
  styleUrls: ['./laporan.component.scss']
})
export class LaporanComponent implements OnInit {
  displayedColumns = ['img', 'nama', 'tgl', 'jlh'];
  listSale: MenuData[] = [];
  finalArr: any = [];
  finalArr2: any = [];
  dataSource: MatTableDataSource<any>;
  mainDB = environment.mainDb;
  listProduk: any = [];
  listReport: ireport[] = [];
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  listMonth: any = [];
  bulan: any;
  date: any = [];

  ExcelData: any = [];
  finalExcel: any[] = [];

  temp_arr: any = [];
  temp_import: any = [];

  excelHeaders: any = ["ID KATEGORI", "ID PRODUK", "NAMA PRODUK", "HARGA"];
  excelIsi: any = ["123", "456", "KECAP", "1200"];
  templateToExcel: any = [];

  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private af: AngularFireAuth,
    private storage: AngularFireStorage,
    private router: Router,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  async ngOnInit() {
    // this.LoadData();
    this.loadFinal();
    this.date = moment().months();
    this.bulan = this.monthNames[this.date];
    this.listMonth = moment.months();
    this.export();
  }

  loadFinal() {
    this.db.list(this.mainDB + '/sale/').snapshotChanges().subscribe(res => {
      // this.listProduk = [];
      res.forEach(sale => {
        let saleId = sale.key;
        this.db.list(this.mainDB + '/sale/' + saleId + '/').snapshotChanges().subscribe(res2 => {
          res2.forEach(sale2 => {
            // console.log(sale2.payload.val());
            this.db.list(this.mainDB + '/kategori/').snapshotChanges().subscribe(items1 => {
              this.listProduk = [];
              items1.forEach(item1 => {
                let idKategori = item1.key;
                this.db.list(this.mainDB + '/kategori/' + idKategori + '/produk/').snapshotChanges().subscribe(items2 => {
                  items2.forEach(item2 => {
                    if (item2.key === sale.key) {
                      this.listProduk.push(
                        getListProduk(
                          idKategori,
                          item2.key,
                          item2.payload.val()['nama'],
                          item2.payload.val()['image'],
                          new Date(Number(sale2.key.split('-')[0])),
                          sale2.payload.val()['jlh'],
                          sale2.payload.val()['satuan']));
                      // console.log(this.listProduk);
                    }
                  });
                  this.dataSource = new MatTableDataSource(this.listProduk);
                  this.dataSource.paginator = this.paginator;
                  this.dataSource.sort = this.sort;
                });
              });
            });
          });
        })
      });
    });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }

  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  onChange(e) {
    let temp = [];
    setTimeout(() => {
      temp = this.listProduk.filter(item => moment(item.penjualan).format("MMMM") === e);
      this.dataSource = new MatTableDataSource(temp);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 500);
    // console.log(temp);
  }


  cetak(): void {
    const dialogRef = this.dialog.open(printDialog, {
      width: '95%',
      height: '90%',
      data: {
        penjualan: this.listProduk,
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  cetakOrder(): void {
    const dialogRef = this.dialog.open(printbyOrderDialog, {
      width: '95%',
      height: '90%',
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  export() {
    this.db.list(this.mainDB + '/kategori')
      .snapshotChanges()
      .subscribe(items => {
        this.listReport = [];
        items.forEach(item => {
          let idkategori = item.key;
          this.db.list(this.mainDB + '/kategori/' + idkategori + '/produk/').snapshotChanges().subscribe(items2 => {
            items2.forEach(row => {
              this.listReport.push(getReport(
                idkategori, row.key, row.payload.val()['nama'], row.payload.val()['sub'], row.payload.val()['ingredients'],
                row.payload.val()['keterangan'], row.payload.val()['stock'], row.payload.val()['harga']
              ));
            });
          });
        });
      });

  }

  toExcel() {
    // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.templateToExcel);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.listReport);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, "MBB Produk" + ".xlsx");
  }

  openfile() {
    $('#fileSrc').trigger('click');
  }

  excelFile(args: any) {
    var files = args.target.files; // FileList object
    this.parseExcel(files[0]);

    // console.log(files);
  }

  parseExcel(file) {
    // console.log(file);
    var reader = new FileReader();
    // var i:number = 0;
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });
      workbook.SheetNames.forEach((async function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);
        // console.log(XL_row_object);
        this.ExcelData.push(XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]));
        this.StoreDB(await this.ExcelData);
        //this.gridObj.dataSource = JSON.parse(json_object);
      }).bind(this), this);
    };

    reader.onerror = function (ex) {
      // console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  gabung(hasil) {
    var harga = [];
    hasil.forEach(b => {
      harga.push({
        'ukuran': b.ukuran === undefined ? '0' : b.ukuran.toString(),
        'ukuranSampai': b.ukuranSampai === undefined ? '0' : b.ukuranSampai.toString(),
        'satuan': b.satuan === undefined ? '' : b.satuan.toString(),
        'nominal': b.nominal === undefined ? '0' : b.nominal.toString(),
      });
      return harga;
    });
    return harga;
  }

  async StoreDB(data) {
    this.temp_import = [];
    this.loadSpinner();
    setTimeout(() => {
      data[0].forEach(async res => {
        // console.log(res);
        this.db.database.ref(this.mainDB + '/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/harga/').set(
          res.harga === undefined ? '' : res.harga,
        );

        this.db.database.ref(this.mainDB + '/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/ingredients/').set(
          res.ingredients === undefined ? '' : res.ingredients,
        );

        this.db.database.ref(this.mainDB + '/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/keterangan/').set(
          res.keterangan === undefined ? '' : res.keterangan,
        );

        this.db.database.ref(this.mainDB + '/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/nama/').set(
          res.nama === undefined ? '' : res.nama,
        );

        this.db.database.ref(this.mainDB + '/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/stock/').set(
          res.stock === undefined ? '' : res.stock,
        );

        this.db.database.ref(this.mainDB + '/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/sub/').set(
          res.sub === undefined ? '' : res.sub,
        );

        // var hasil = data[0].filter(e => e.idproduk === res.idproduk);
        // var harga = await this.gabung(hasil);
        // this.db.database.ref(this.mainDB + '/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/disc/').set(
        //   res.disc === undefined ? 0 : res.disc,
        // );

        // this.db.database.ref(this.mainDB + '/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/kandungan/').set(
        //   res.kandungan === undefined ? '' : res.kandungan,
        // );

        // this.db.database.ref(this.mainDB + '/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/nama/').set(
        //   res.nama === undefined ? '' : res.nama,
        // );

        // this.db.database.ref(this.mainDB + '/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/sub/').set(
        //   res.sub === undefined ? '' : res.sub,
        // );

        // this.db.database.ref(this.mainDB + '/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/type/').set(
        //   res.type === undefined ? '' : res.type
        // );

        // this.db.database.ref(this.mainDB + '/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/harga/').set(
        //   harga,
        // );
      });
      this.closeSpinner();
    }, 1000);
  }
}


@Component({
  selector: 'print-dialog',
  templateUrl: './printdialog.html',
  styleUrls: ['./laporan.component.scss']
})
export class printDialog {
  constructor(
    public dialogRef: MatDialogRef<printDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,) { }

  ngOnInit(): void {
    console.log(this.data);
  }
}


@Component({
  selector: 'print-dialog',
  templateUrl: './printbyorderdialog.html',
  styleUrls: ['./laporan.component.scss']
})
export class printbyOrderDialog {
  mainDB = environment.mainDb;
  listmenu: MenuOrder[] = [];
  constructor(
    public dialogRef: MatDialogRef<printDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,) { }

  ngOnInit(): void {
    this.loadpenjualan();
  }

  loadpenjualan() {
    this.db.list(this.mainDB + '/Penjualan/').snapshotChanges().subscribe(items => {
      items.forEach(item => {
        var idInduk = item.key;
        this.listmenu = [];
        this.db.list(this.mainDB + '/Penjualan/' + idInduk).snapshotChanges().subscribe(item2 => {
          item2.forEach(order => {
            console.log(order.payload.val());
            this.listmenu.push(GetListOrder(idInduk, order.key, order.payload.val()['carabayar'], order.payload.val()['kurir'], order.payload.val()['nama'],
              order.payload.val()['pengiriman'], order.payload.val()['tgl'], order.payload.val()['status'], order.payload.val()['tokenFcm'],
              order.payload.val()['totalbayar'], order.payload.val()['chart']));
          });
          // this.onNew();
          // this.dataSource = new MatTableDataSource(this.listmenu);
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
        });
      });
    });
  }
}

function getListSale(idProduk: string, idList: string, jlh: string): MenuData {
  return {
    idProduk: idProduk,
    idList: idList,
    jlh: jlh,
  }
}

function getListProduk(idKategori: string, idProduk: string, nama: string,
  image: any[], penjualan: any, jlh: string, satuan: string): MenuProduk {
  return {
    idKategori: idKategori,
    idProduk: idProduk,
    nama: nama,
    image: image,
    penjualan: penjualan,
    jlh: jlh,
    satuan: satuan,
  }
}

function GetListOrder(idInduk: string, id: string, carabayar: string, kurir: any, nama: string, pengiriman: any, tgl: any,
  status: string, tokenFcm: any, totalbayar: number, chart: chart[],): MenuOrder {

  return {
    idInduk: idInduk.toString(),
    id: id.toString(),
    carabayar: carabayar,
    kurir: kurir,
    nama: nama,
    pengiriman: pengiriman,
    tgl: tgl,
    status: status,
    tokenFcm: tokenFcm,
    totalbayar: totalbayar,
    chart: chart,
  };
}

export interface MenuData {
  idProduk: string;
  idList: string;
  jlh: string;
}

export interface MenuProduk {
  idKategori: string;
  idProduk: string;
  nama: string,
  image: any[];
  penjualan: any,
  jlh: string,
  satuan: string,
}

export interface MenuOrder {
  idInduk: string;
  id: string;
  carabayar: string;
  kurir: any[];
  nama: string;
  pengiriman: any;
  status: string;
  tgl: any;
  tokenFcm: any;
  totalbayar: number;
  chart: chart[];
}

export interface chart {
  idProduk: string;
  harga: number;
  jumlah: number;
  namaproduk: string;
  url: any;

}

function getReport(idkategori: string, idproduk: string,
  nama: string, sub: string, ingredients: string, keterangan: string, stock: any,
  harga: any): ireport {
  return {
    idkategori: idkategori,
    idproduk: idproduk,
    nama: nama,
    sub: sub,
    ingredients: ingredients,
    keterangan: keterangan,
    stock: stock,
    harga: harga,
  }
}

export interface ireport {
  idkategori: string;
  idproduk: string;
  nama: string;
  sub: string;
  ingredients: string;
  keterangan: string;
  stock: any;
  harga: any;
}

export const COMPONENT_LIST = [
  LaporanComponent, printDialog, printbyOrderDialog,
]
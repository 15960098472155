import { Component, OnInit, ViewChild, Inject, ViewEncapsulation } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { SellerModel, SupplierServicesService } from './supplier-services/supplier-services.service';
import { iSupplier, iAlamat } from './supplier-model/supplier-model'
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss'],
  providers: [SupplierServicesService]
})

export class SupplierComponent implements OnInit {
  displayedColumns = ['photo','nama', 'alamat', 'kontak', 'aksi'];

  dialogRef: any;
  dataSource: MatTableDataSource<SellerModel>;
  supplierCompleteData: SellerModel[] = [];

  private Unsubscribe = new Subject();

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public supplierServicesService: SupplierServicesService,
    public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.supplierServicesService.getItems().subscribe((val) => {
      this.supplierCompleteData = val;
      this.spinnerWithoutBackdrop = false;
      this.dataSource = new MatTableDataSource(this.supplierCompleteData)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
    });
    // this.supplierServicesService.getItems()
    //   .pipe(
    //     takeUntil(this.Unsubscribe)
    //   ).subscribe((supplierAll) => {
    //     this.supplierCompleteData = supplierAll
    //     console.log(this.supplierCompleteData)
        // this.dataSource = new MatTableDataSource(this.supplierCompleteData)
        // this.dataSource.paginator = this.paginator
        // this.dataSource.sort = this.sort
    //   })
  }

  ngOnDestroy() {
    this.Unsubscribe.next();
    this.Unsubscribe.complete();
  }

  addSupplier() {
    this.dialogRef = this.dialog.open(addSupplierDialog, {
      panelClass: 'addkurir',
      width: '35%',
      data: {
        action: 'new',
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {

    });
  }


  onEdit(e): void {
    this.dialogRef = this.dialog.open(addSupplierDialog, {
      panelClass: 'addkurir',
      width: '35%',
      data: {
        action: 'edit',
        item: e,
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {

    });
  }

  konfirmasi(val){
    var r = confirm("Yakin ingin konfirmasi seller " + val.name + " ?");
    if (r === true) {
      this.loadSpinner();
      this.supplierServicesService.approve(val.id).then(() => {
        this.closeSpinner()
      })
    }
  }

  block(val){
    var r = confirm("Yakin ingin block seller " + val.name + " ?");
    if (r === true) {
      this.loadSpinner();
      this.supplierServicesService.block(val.id).then(() => {
        this.closeSpinner()
      })
    }
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  ChatKurir(e) {
    window.open("https://wa.me/" + e + "?text=Halo!, Kami dari Kumpool Shop", "_blank");
  }
}
@Component({
  selector: 'addsupplier',
  templateUrl: 'addsupplier/addsupplier.component.html',
  styleUrls: ['addsupplier/addsupplier.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
  providers: [SupplierServicesService]
})
export class addSupplierDialog {
  kurirFrom: FormGroup;
  title: string;
  tombol: string;
  dataProvinsi: iAlamat[];
  dataKota: iAlamat[];
  dataKecamatan: iAlamat[];
  dataKelurahan: iAlamat[];
  selectedKota: iAlamat;
  selectedProvinsi: iAlamat;
  selectedKecamatan: iAlamat;
  selectedKelurahan: iAlamat;

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  constructor(
    public dialog: MatDialog,
    public supplierServicesService: SupplierServicesService,
    public apiService: ApiService,
    public dialogRef: MatDialogRef<addSupplierDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // this.ambilProvinsi();
    this.kurirFrom = new FormGroup({
      nama: new FormControl('', [Validators.required]),
      kontak: new FormControl('', [Validators.required]),
      alamat: new FormControl('', [Validators.required]),
      kota: new FormControl('', [Validators.required]),
      kecamatan: new FormControl('', [Validators.required]),
      kelurahan: new FormControl('', [Validators.required]),
      provinsi: new FormControl('', [Validators.required]),
    });

    if (this.data.action === 'new') {
      this.title = 'Tambah';
      this.tombol = 'Simpan';
    } else if (this.data.action === 'edit') {
      // if (this.data.item.provinsi !== undefined) {
      //   this.ambilKota(this.data.item.provinsi);
      // }
      // if (this.data.item.kota !== undefined) {
      //   this.ambilKecamatan(this.data.item.kota)
      // }
      // if (this.data.item.kecamatan !== undefined) {
      //   this.ambilKelurahan(this.data.item.kecamatan)
      // }
      this.title = 'Edit';
      this.tombol = 'Edit';
      this.kurirFrom.patchValue({
        nama: this.data.item.nama,
        kontak: this.data.item.kontak,
        alamat: this.data.item.alamat,
        provinsi: this.data.item.provinsi !== undefined ? this.data.item.provinsi : '',
        kota: this.data.item.kota !== undefined ? this.data.item.kota : '',
        kecamatan: this.data.item.kecamatan !== undefined ? this.data.item.kecamatan: '',
        kelurahan: this.data.item.kelurahan !== undefined ? this.data.item.kelurahan : '',
      })

    }
  }
  ambilProvinsi() {
    this.loadSpinner();
    this.dataProvinsi = [];
    this.selectedProvinsi = null;
    this.apiService.getAPitoken().subscribe(result => {
      this.apiService.getApiData(result['token'], '/m/wilayah/provinsi',).subscribe(result => {
        this.dataProvinsi = result['data'];
        this.closeSpinner();
      })
    });
  }
  ambilKota(data: iAlamat) {
    this.loadSpinner();
    this.dataKota = [];
    this.selectedProvinsi = data;
    this.selectedKota = null;
    this.apiService.getAPitoken().subscribe(result => {
      this.apiService.getApiData(result['token'], '/m/wilayah/kabupaten?idpropinsi=' + data.id,).subscribe(result => {
        this.dataKota = result['data'];
        this.closeSpinner();
      })
    });
  }

  ambilKecamatan(data: iAlamat) {
    this.loadSpinner();
    this.dataKecamatan = [];
    this.selectedKota = data;
    this.selectedKecamatan = null;
    this.apiService.getAPitoken().subscribe(result => {
      this.apiService.getApiData(result['token'], '/m/wilayah/kecamatan?idkabupaten=' + data.id,).subscribe(result => {
        this.dataKecamatan = result['data'];
        this.closeSpinner();
      })
    });
  }
  ambilKelurahan(data: iAlamat) {
    this.loadSpinner();
    this.dataKelurahan = [];
    this.selectedKecamatan = data;
    this.selectedKelurahan = null
    this.apiService.getAPitoken().subscribe(result => {
      this.apiService.getApiData(result['token'], '/m/wilayah/kelurahan?idkecamatan=' + data.id,).subscribe(result => {
        this.dataKelurahan = result['data'];
        this.closeSpinner();
      })
    });
  }
  selectKelurahan(data: iAlamat) {
    this.selectedKelurahan = data;
  }
  // onSave() {
  //   this.loadSpinner()
  //   if (this.data.action === 'new') {
  //     this.supplierServicesService.postItems({
  //       nama: this.kurirFrom.get('nama').value,
  //       kontak: this.kurirFrom.get('kontak').value,
  //       alamat: this.kurirFrom.get('alamat').value,
  //       provinsi: this.kurirFrom.get('provinsi').value,
  //       kota: this.kurirFrom.get('kota').value,
  //       kecamatan: this.kurirFrom.get('kecamatan').value,
  //       kelurahan: this.kurirFrom.get('kelurahan').value,
  //     }).then(() => {
  //       this.closeSpinner()
  //       this.dialogRef.close()
  //       this.kurirFrom.reset()
  //     })
  //   } else {
  //     this.supplierServicesService.updateItems({
  //       key: this.data.item.key,
  //       nama: this.kurirFrom.get('nama').value,
  //       kontak: this.kurirFrom.get('kontak').value,
  //       alamat: this.kurirFrom.get('alamat').value,
  //       provinsi: this.kurirFrom.get('provinsi').value,
  //       kota: this.kurirFrom.get('kota').value,
  //       kecamatan: this.kurirFrom.get('kecamatan').value,
  //       kelurahan: this.kurirFrom.get('kelurahan').value,
  //     }).then(() => {
  //       this.closeSpinner()
  //       this.dialogRef.close()
  //       this.kurirFrom.reset()
  //     })
  //   }
  // }

  onDelete() {
    var r = confirm("Yakin ingin menghapus " + this.data.item.nama + " ?");
    if (r === true) {
      this.loadSpinner();
      // this.supplierServicesService.deleteItems(this.data.item.key).then(() => {
      //   this.closeSpinner()
      //   this.dialogRef.close()
      // })
    }
  }


  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }
}
export const COMPONENT_LIST = [SupplierComponent, addSupplierDialog]
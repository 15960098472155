import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
//import { DOCUMENT } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
declare var jQuery: any;
import { saveAs } from 'file-saver';
import { environment } from 'environments/environment';


@Component({
  selector: 'daftar-harga',
  templateUrl: './daftar-harga.component.html',
  styleUrls: ['./daftar-harga.component.scss']
})
export class DaftarHargaComponent implements OnInit {

  listbrg: Pengaturanbrg[] = [];
  dialogRef: any = [];
  mainDB = environment.mainDb;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  dataSource: MatTableDataSource<Pengaturanbrg>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    public dialogBank: MatDialog,
    private _fuseSplashScreenService: FuseSplashScreenService,
    public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.loadharga();
  }

  loadharga() {
    this.db.list(this.mainDB + '/kategori')
      .snapshotChanges()
      .subscribe(items => {
        this.listbrg = [];
        items.forEach(item => {
          let idkategori = item.key;
          this.db.list(this.mainDB + '/kategori/' + idkategori + '/produk/').snapshotChanges().subscribe(items2 => {
            items2.forEach(item2 => {
              // console.log(item2.payload.val()['disc']);
              this.listbrg.push(getBrg(idkategori, item2.key, item2.payload.val()['nama'], item2.payload.val()['image'],
                item2.payload.val()['harga'], item2.payload.val()['hargaReseller'], item2.payload.val()['stock'], item2.payload.val()['disc'] !== undefined ? item2.payload.val()['disc'] : 0, item2.payload.val()['satuan']));
            });
            // console.log(this.listbrg);
            this.dataSource = new MatTableDataSource(this.listbrg);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
        });
      });
  }


  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }

  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getRecord(item) {
    const dialogref = this.dialogBank.open(editBrg, {
      panelClass: 'dialog',
      hasBackdrop: true,
      width: '70%',
      height: '70%',
      data: item,
    });

    dialogref.afterClosed().subscribe(result => {
      if (result !== 'close') {
        // console.log(result);
        // this.loadSpinner();
        this.db.database.ref(this.mainDB + '/kategori/' + result.idkategori + '/produk/' + result.idproduk + '/harga/').set(
          result.harga
        );
        this.db.database.ref(this.mainDB + '/kategori/' + result.idkategori + '/produk/' + result.idproduk + '/hargaReseller/').set(
          result.hargaReseller
        );

        this.db.database.ref(this.mainDB + '/kategori/' + result.idkategori + '/produk/' + result.idproduk + '/stock/').set(
          result.stock
        );

        this.db.database.ref(this.mainDB + '/kategori/' + result.idkategori + '/produk/' + result.idproduk + '/disc/').set(
          result.disc
        );
      }
    });
  }
}
@Component({
  selector: 'editbank-dialog',
  templateUrl: 'dialogharga/editharga-dialog.html',
  styleUrls: ['dialogharga/editharga-dialog.scss']
})
export class editBrg {
  Modelbrg: any = [];
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDB = environment.mainDb;
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialogRef<editBrg>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.Modelbrg = this.data;
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }

  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  // getHarga(e) {
  //   var harga = [];
  //   e.harga.forEach(item => {
  //     harga.push({
  //       'nominal': item.nominal.toString(),
  //       'satuan': item.satuan.toString(),
  //       'ukuran': item.ukuran.toString(),
  //       'ukuranSampai': item.ukuranSampai.toString(),
  //     });
  //     return harga;
  //   });
  //   return harga;
  // }

  async onSave() {
    // var harga = await this.getHarga(this.Modelbrg);
    // console.log(this.Modelbrg);
    this.loadSpinner();
    setTimeout(() => {
      this.dialog.close({
        'idkategori': this.Modelbrg.idkategori,
        'idproduk': this.Modelbrg.idproduk,
        'harga': this.Modelbrg.harga,
        'stock': this.Modelbrg.stock,
        'disc': this.Modelbrg.disc,
      });
      this.closeSpinner();
    }, 500);
  }
}

function getBrg(idkategori: string, idproduk: string, nmProduk: string, img: any, harga: any, hargaReseller: number, stock: any, disc: any, satuan: string): Pengaturanbrg {
  return {
    idkategori: idkategori,
    idproduk: idproduk,
    nmProduk: nmProduk,
    img: img,
    harga: harga,
    hargaReseller: hargaReseller,
    stock: stock,
    disc: disc,
    satuan: satuan,
  }
}

export interface Pengaturanbrg {
  idkategori: string;
  idproduk: string;
  nmProduk: string;
  img: any[];
  harga: any;
  hargaReseller: number;
  stock: any;
  disc: any;
  satuan: string;
}
export const COMPONENT_LIST = [
  DaftarHargaComponent, editBrg
]
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubCategory } from './addsub/model';
import { ProdukData } from './daftarmenu.component';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  itemsRef: AngularFireList<SubCategory>;
  items: Observable<SubCategory[]>;
  itemsProd: Observable<ProdukData[]>;
  mainDB = environment.mainDb;
  constructor(
    public storage: AngularFireStorage,
    private db: AngularFireDatabase) {

  }

  getItems(id): Observable<SubCategory[]> {
    this.itemsRef = this.db.list(this.mainDB + 'kategori/' + id + '/subCategory');
    this.items = this.itemsRef.snapshotChanges()
      .pipe(map(change => {
        return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      }))
    return this.items;
  }

  getItemsProduk(id): Observable<any> {
    let itemsRefP: AngularFireList<ProdukData> = this.db.list(this.mainDB + '/produk', ref => ref.orderByChild('idCategory').equalTo(id));
    let itemsProd = itemsRefP.snapshotChanges()
      .pipe(map(change => {
        return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      }))
    return itemsProd;
  }

  async simpanCat(data: SubCategory[], id): Promise<boolean> {
    let hasil: boolean;
    let ref = this.db.database.ref(this.mainDB + 'kategori/' + id + '/subCategory');
    await ref
      .set(data).then((res) => {
        hasil = true;
        return hasil;
      })
      .catch(
        (error: any) => {
          hasil = false;
          return hasil;
          // console.dir(supplierItem);
        });
    return hasil;
  }
}

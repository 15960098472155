import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
//import { DOCUMENT } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable, Subject } from 'rxjs';
import { finalize, subscribeOn, skip, takeUntil, startWith } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError, MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
declare var jQuery: any;
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { SupplierServicesService } from '../supplier/supplier-services/supplier-services.service'
import { environment } from '../../../environments/environment';
import { AddsubComponent } from './addsub/addsub.component';
import { SubCategory, TagProduk } from './addsub/model';
import { COMMA, ENTER, } from '@angular/cdk/keycodes';
import { ApiServiceService } from './api-service.service';
import { ServicesService } from '../category/services.service';
import { CategoryModel } from '../category/model.component';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'daftarmenu',
  templateUrl: './daftarmenu.component.html',
  styleUrls: ['./daftarmenu.component.scss']
})
export class DaftarMenu {
  ref: any = [];
  items: any[];
  selectedRowIndex = [];
  displayedColumns = ['id', 'category'];
  loadRef: any = [];
  dialogRef: any = [];
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  listmenu: MenuData[] = [];
  dataSource: MatTableDataSource<MenuData>;
  obs: Observable<any>;
  mainDB = environment.mainDb;
  auth: any;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private router: Router,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  async ngOnInit() {
    this.auth = await JSON.parse(localStorage.getItem("AUTH"));
    if (this.auth === null) {
      this.router.navigate(['/login']);
    }

    this.loadSpinner();
    this.db.list(this.mainDB + '/kategori')
      .snapshotChanges()
      .subscribe(items => {
        this.listmenu = [];
        items.forEach(item => {
          this.listmenu.push(GetListMenu(item.payload.val()['nama'], item.key, item.payload.val()['image'], item.payload.val()['filename'], item.payload.val()['produk'], item.payload.val()['publish']));
          // console.log(this.listmenu);
        });
        this.dataSource = new MatTableDataSource(this.listmenu.sort((a, b) => a.category.toLowerCase().localeCompare(b.category.toLowerCase())));
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.obs = this.dataSource.connect();
        this.closeSpinner();
      });
    let oldToken = await localStorage.getItem('FCM');
    this.db.database.ref(this.mainDB + '/server/' + this.auth.uid).set({
      'token': oldToken,
    });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  delRecord(item) {
    var r = confirm("Yakin ingin menghapus " + item.category + " ?");
    if (r === true) {
      this.loadSpinner();
      this.db.database.ref(this.mainDB + '/kategori/' + item.id).remove();
      this.storage.ref(item.filename).delete().subscribe(() => {
        this.closeSpinner();
      });
    }
  }
  publish(item) {
    this.db.database.ref(this.mainDB + '/kategori/' + item.id + '/publish').set(true);
  }

  unpublish(item) {
    this.db.database.ref(this.mainDB + '/kategori/' + item.id + '/publish').set(false);
  }
  subCat(row) {
    const dialogRef = this.dialog.open(AddsubComponent, {
      panelClass: 'app-addSub',
      hasBackdrop: true,
      // width: '40%',
      // height: '90%',
      // maxWidth: '50vw',
      // maxHeight: '70vh',
      data: {
        row: row,
      }
    });
    dialogRef.afterClosed().subscribe((res) => {

    });
  }
  getRecord(row) {
    this.dialogRef = this.dialog.open(DialogDaftarmenu, {
      data: row,
    });
  }

  getList(item) {
    this.dialogRef = this.dialog.open(DialogDaftarProduk, {
      height: '95%',
      width: '99%',
      data: item,
    });
  }

  addProduct() {
    this.dialogRef = this.dialog.open(DialogDaftarmenu, {
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

@Component({
  selector: 'inputProdukImage-dialog',
  templateUrl: 'inputProdukImage-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogProdukImage {
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  modelProduct: any = [];
  ListImage = [];
  imageChangedEvent: any = '';
  gambar: any;
  croppedImage: any = '';
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,

    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;
  ngOnInit() {
    if (this.data !== null) {
      this.gambar = this.data.image;
    }
  }
  openfile() {
    jQuery('#imgupload').trigger('click');
  }
  getFileAttachment(event: any) {
    this.imageChangedEvent = event;

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this.gambar = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}
@Component({
  selector: 'inputProduk-dialog',
  templateUrl: 'inputProduk-dialog.html',
  styleUrls: ['daftarmenu.component.scss'],
  providers: [SupplierServicesService]
})
export class DialogInputProduk {
  arrival: boolean = false;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  donwloadLink: any = [];
  myControl = new FormControl();
  kategori: any;
  oldGambar: any;
  flag: boolean = true;
  key: any;
  imageChangedEvent: any = '';

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  modelProduct: any = {};

  ListImage: any = [];
  ListImageAsal: any = [];
  ListImageEdit: any = [];

  satuan: Satuan[] = [];

  options: iSupplier[] = [];
  filteredOptions: Observable<iSupplier[]>;
  mainDB = environment.mainDb;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  tagProduk: TagProduk[] = [];
  ListCat: SubCategory[] = [];
  ListSub: CategoryModel[] = [];
  ListKelamin: any = [
    { 'nama': 'Pria' },
    { 'nama': 'Wanita' },
    { 'nama': 'Unisex' },
  ];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA,];
  constructor(
    private storage: AngularFireStorage,
    public dbService: ApiServiceService,
    public dbContent: ServicesService,
    public dialogRef: MatDialogRef<DialogProdukImage>,
    public dialogInput: MatDialogRef<DialogInputProduk>,
    private db: AngularFireDatabase,
    private _supplierServicesService: SupplierServicesService,
    public dialog: MatDialog,
    iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    iconRegistry.addSvgIcon(
      'photo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/add_photo.svg'));
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg'));
  }
  async ngOnInit() {
    // await this._supplierServicesService.getItems().subscribe((result) => {
    //   this.options = result;
    // });
    this.dbService.getItems(this.data.idkategori).subscribe((result) => {
      this.ListCat = result;
    });
    this.dbContent.getItems().subscribe((result) => {
      this.ListSub = result;
    });

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nama),
        map(nama => nama ? this._filter(nama) : this.options.slice())
      );
    this.loadSatuan();
    if (this.data.type === 'edit') {
      this.arrival = this.data.data.arrival;
      this.modelProduct.nama = this.data.data.nama;
      this.modelProduct.sub = this.data.data.sub;
      this.modelProduct.ukuran = this.data.data.ukuran;
      this.modelProduct.satuan = this.data.data.satuan;
      this.modelProduct.harga = this.data.data.harga;
      this.modelProduct.hargaReseller = this.data.data.hargaReseller;
      this.modelProduct.disc = this.data.data.disc;
      this.modelProduct.type = this.data.data.type;
      this.modelProduct.keterangan = this.data.data.keterangan;
      // this.modelProduct.ingredients = this.data.data.ingredients;
      this.modelProduct.stock = this.data.data.stock;
      this.modelProduct.ukuranKurir = this.data.data.ukuranKurir;
      this.modelProduct.contentCategory = this.data.data.contentCategory;
      this.modelProduct.subCategory = this.data.data.subCategory;
      this.modelProduct.sex = this.data.data.sex;
      this.tagProduk = this.data.data.tag !== undefined ? this.data.data.tag : [];
      //console.log(this.data.data.supplier)
      // this.ListImage = this.data.data.listImage;
      this.data.data.image.forEach((item) => {
        this.ListImage.push({
          url: item.url,
          asal: 1,
        });
      });
    } else {
      this.modelProduct = [];
    }


  }



  remove(data: TagProduk): void {
    const index = this.tagProduk.indexOf(data);
    if (index >= 0) {
      this.tagProduk.splice(index, 1);
    }
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.tagProduk.push({ namaTag: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  private _filter(name: string): iSupplier[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.nama.toLowerCase().indexOf(filterValue) === 0);
  }
  // async filterEdit(data) {
  //   this._supplierServicesService.getItems().subscribe((val) => {
  //     val.forEach(item => {
  //       if (item.key === data) {
  //         this.modelProduct.namaSupplier = item.nama;
  //       }
  //     })
  //   });
  // }

  // displayFn(user: iSupplier): string {
  //   return user.nama;
  // }
  displayFn(user?: iSupplier): string | undefined {
    return user ? user.nama : undefined;
  }

  _allowSelection(option: string): { [className: string]: boolean } {
    return {
      'no-data': option === 'No data',
    };
  }


  test() {
    console.log(this.modelProduct.supplier.key)
  }

  loadSatuan() {
    this.db.list(this.mainDB + '/satuan/').snapshotChanges().subscribe(item => {
      this.satuan = [];
      item.forEach(e => {
        this.satuan.push(GetSatuan(e.key, e.payload.val()['viewValue']));
      });
    })
  }

  clickNew() {
    this.dialogRef = this.dialog.open(DialogProdukImage, {
    });
    this.dialogRef.afterClosed().subscribe(result => {
      console.log(result.file)
      if (result !== '') {
        let gbr = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          + result.base64);
        // this.ListImage.push({ file: result.file, image: result.base64 });
        this.ListImage.push({ asal: 0, file: result.file, url: result.base64 });
        this.ListImageAsal.push(result.file);
      }
    });
  }

  RemoveMore(index, item) {
    this.ListImage.splice(index, 1);
    var str = item.url;
    var pecah = str.split("?");
    var pecah2 = pecah[0].split("%2F");
    this.ListImageEdit.push(pecah2[1]);
    // console.log(this.ListImageEdit);
  }

  async delImage() {
    if (this.data.type === 'edit') {
      this.ListImageEdit.forEach((item) => {
        this.storage.ref(this.data.nmkategori + '/' + item).delete();
      });
      return true;
    } else {
      return true;
    }
  }

  async getImage() {
    if (this.data.type === 'edit') {
      this.ListImage.forEach((item) => {
        if (item.asal == 1) {
          this.donwloadLink.push({
            url: item.url,
          });
        }
      });
    }
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  async editCategory() {
    console.log(this.modelProduct);
    if (this.modelProduct.nama === undefined) {
      alert('Nama Produk harus di isi');
      return
    }

    if (this.modelProduct.keterangan === undefined) {
      alert('Keterangan harus di isi');
      return
    }
    if (this.modelProduct.ukuran === undefined) {
      alert('Ukuran harus di isi');
      return
    }
    if (this.modelProduct.satuan === undefined) {
      alert('Satuan produk harus di isi');
      return
    }
    if (this.modelProduct.ukuranKurir === undefined) {
      alert('Ukuran Berat kurir harus di isi');
      return
    }
    if (this.tagProduk.length === 0) {
      alert('Tag belum diisi');
      return
    }
    if (this.modelProduct.contentCategory === undefined) {
      alert('Kontent kategori harus di isi')
      return;
    }



    this.loadSpinner();
    await this.delImage();
    await this.getImage();
    var currentUnixTime = Date.now();
    const allPercentage: Observable<number>[] = [];

    if (this.data.type !== 'edit') {
      if (this.ListImage.length < 1) {
        alert('Image Minimal');
        return
      }

      for (const { item, index } of this.ListImage.map((item, index) => ({ item, index }))) {
        const filePath = this.data.nmkategori + '/' + this.data.idkategori + '_' + currentUnixTime + '_' + index;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, item.file);
        const _percentage$ = task.percentageChanges();
        allPercentage.push(_percentage$);

        // observe percentage changes
        this.uploadPercent = task.percentageChanges();

        // get notified when the download URL is available
        task.snapshotChanges().pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe(url => {
              this.donwloadLink.push({ 'url': url });
              if (this.ListImage.length === this.donwloadLink.length) {
                this.db.database.ref(this.mainDB + '/produk/' + currentUnixTime).set(
                  {
                    'nama': this.modelProduct.nama.toString(),
                    'supplier': this.modelProduct.supplier === undefined ? this.data.data.supplier : this.modelProduct.supplier.key,
                    'sub': this.modelProduct.sub === undefined ? " " : this.modelProduct.sub.toString(),
                    'keterangan': this.modelProduct.keterangan === undefined ? '' : this.modelProduct.keterangan.toString(),
                    // 'ingredients': this.modelProduct.ingredients === undefined ? '' : this.modelProduct.ingredients.toString(),
                    'harga': this.modelProduct.harga === undefined ? 0 : this.modelProduct.harga,
                    'hargaReseller': this.modelProduct.hargaReseller === undefined ? 0 : this.modelProduct.hargaReseller,
                    'ukuran': this.modelProduct.ukuran,
                    'ukuranKurir': this.modelProduct.ukuranKurir,
                    'satuan': this.modelProduct.satuan,
                    'disc': this.modelProduct.disc === undefined ? 0 : this.modelProduct.disc,
                    'image': this.donwloadLink,
                    'arrival': this.arrival,
                    'publish': true,
                    'tag': this.tagProduk,
                    'idCategory': this.data.idkategori,
                    'contentCategory': this.modelProduct.contentCategory,
                    'subCategory': this.modelProduct.subCategory === undefined ? "" : this.modelProduct.subCategory,
                    'sex': this.modelProduct.sex === undefined ? "" : this.modelProduct.sex,
                    'stock': this.modelProduct.stock === undefined ? 0 : this.modelProduct.stock,
                  }
                ).then(() => {
                  this.closeSpinner();
                  this.dialogInput.close();
                })
              }
            }
            );
          })
        ).subscribe();
      }
    } else {
      if (this.ListImageAsal.length > 0) {
        for (const { item, index } of this.ListImage.map((item, index) => ({ item, index }))) {
          const filePath = this.data.nmkategori + '/' + this.data.idkategori + '_' + currentUnixTime + '_' + index;
          const fileRef = this.storage.ref(filePath);
          if (item.asal == 0) {
            const task = this.storage.upload(filePath, item.file);
            const _percentage$ = task.percentageChanges();
            allPercentage.push(_percentage$);

            // observe percentage changes
            this.uploadPercent = task.percentageChanges();

            // get notified when the download URL is available
            task.snapshotChanges().pipe(
              finalize(() => {
                fileRef.getDownloadURL().subscribe(url => {
                  this.donwloadLink.push({ 'url': url });
                  if (this.ListImage.length === this.donwloadLink.length) {
                    this.db.database.ref(this.mainDB + '/produk/' + this.data.data.id).set(
                      {
                        'nama': this.modelProduct.nama.toString(),
                        'supplier': this.modelProduct.supplier === undefined ? this.data.data.supplier : this.modelProduct.supplier.key,
                        'sub': this.modelProduct.sub === undefined ? '' : this.modelProduct.sub.toString(),
                        'keterangan': this.modelProduct.keterangan === undefined ? '' : this.modelProduct.keterangan.toString(),
                        // 'ingredients': this.modelProduct.ingredients === undefined ? '' : this.modelProduct.ingredients.toString(),
                        'harga': this.modelProduct.harga === undefined ? 0 : this.modelProduct.harga,
                        'hargaReseller': this.modelProduct.hargaReseller === undefined ? 0 : this.modelProduct.hargaReseller,
                        'ukuran': this.modelProduct.ukuran,
                        'ukuranKurir': this.modelProduct.ukuranKurir,
                        'satuan': this.modelProduct.satuan,
                        'disc': this.modelProduct.disc === undefined ? 0 : this.modelProduct.disc,
                        'image': this.donwloadLink,
                        'arrival': this.arrival,
                        'publish': true,
                        'tag': this.tagProduk,
                        'idCategory': this.data.idkategori,
                        'contentCategory': this.modelProduct.contentCategory,
                        'subCategory': this.modelProduct.subCategory === undefined ? "" : this.modelProduct.subCategory,
                        'sex': this.modelProduct.sex === undefined ? "" : this.modelProduct.sex,
                        'stock': this.modelProduct.stock === undefined ? 0 : this.modelProduct.stock,
                      }).then(() => {
                        this.closeSpinner();
                        this.dialogInput.close();
                      });
                  }
                }
                );
              })
            ).subscribe();
          }
        }
      } else {
        this.db.database.ref(this.mainDB + '/produk/' + this.data.data.id).set(
          {
            'nama': this.modelProduct.nama.toString(),
            'supplier': this.modelProduct.supplier === undefined ? this.data.data.supplier : this.modelProduct.supplier.key,
            'sub': this.modelProduct.sub === undefined ? '' : this.modelProduct.sub.toString(),
            'keterangan': this.modelProduct.keterangan === undefined ? '' : this.modelProduct.keterangan.toString(),
            //'ingredients': this.modelProduct.ingredients === undefined ? '' : this.modelProduct.ingredients.toString(),
            'harga': this.modelProduct.harga === undefined ? 0 : this.modelProduct.harga,
            'hargaReseller': this.modelProduct.hargaReseller === undefined ? 0 : this.modelProduct.hargaReseller,
            'ukuran': this.modelProduct.ukuran === undefined ? 0 : this.modelProduct.ukuran,
            'satuan': this.modelProduct.satuan === undefined ? 0 : this.modelProduct.satuan.toString(),
            'ukuranKurir': this.modelProduct.ukuranKurir === undefined ? 0 : this.modelProduct.ukuranKurir,
            'disc': this.modelProduct.disc === undefined ? 0 : this.modelProduct.disc,
            'image': this.donwloadLink,
            'arrival': this.arrival,
            'publish': true,
            'tag': this.tagProduk,
            'idCategory': this.data.idkategori,
            'contentCategory': this.modelProduct.contentCategory,
            'subCategory': this.modelProduct.subCategory === undefined ? "" : this.modelProduct.subCategory,
            'sex': this.modelProduct.sex === undefined ? "" : this.modelProduct.sex,
            'stock': this.modelProduct.stock === undefined ? 0 : this.modelProduct.stock,
          }).then(() => {
            this.closeSpinner();
            this.dialogInput.close();
          });
      }
    }

  }

}

@Component({
  selector: 'daftarproduct-dialog',
  templateUrl: 'daftarproduk-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogDaftarProduk {
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  gambar: any;
  kategori: any;
  oldGambar: any;
  flag: boolean = true;
  key: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  selectedRowIndex: string;
  listproduk: ProdukData[] = [];
  dialogRef: any = [];
  dataSource: MatTableDataSource<ProdukData>;
  mainDB = environment.mainDb;
  private Unsubscribe = new Subject();
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dbService: ApiServiceService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit() {
    console.log(this.data);
    this.kategori = this.data.category.toUpperCase();
    this.loadSpinner();
    this.getItems();
  }
  getItems() {
    this.dbService.getItemsProduk(this.data.id)
      .pipe(
        takeUntil(this.Unsubscribe)
      ).subscribe((result) => {
        this.listproduk = result;
        this.dataSource = new MatTableDataSource(this.listproduk.sort((a, b) => a.nama.toLowerCase().localeCompare(b.nama.toLowerCase())));
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
  }



  imgDelete(item) {
    item.image.forEach((item) => {
      var str = item.url;
      var pecah = str.split("?");
      var pecah2 = pecah[0].split("%2F");
      this.storage.ref(this.data.category + '/' + pecah2[1]).delete();
    });
  }
  async delRecord(item) {
    // console.log(item);
    var r = confirm("Yakin ingin menghapus " + item.nama + " ?");
    if (r == true) {
      this.loadSpinner();
      await this.imgDelete(item);
      this.db.database.ref(this.mainDB +  '/produk/' + item.id).remove().then(() => {
        this.closeSpinner();
      });
      this.closeSpinner();
    }
  }

  sortAZ() {
    this.listproduk.sort((a, b) => a.nama.toLowerCase().localeCompare(b.nama.toLowerCase()));
  }

  sortZA() {
    this.listproduk.sort((a, b) => b.nama.toLowerCase().localeCompare(a.nama.toLowerCase()));
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }

  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  publish(item) {
    this.db.database.ref(this.mainDB + '/produk/' + item.id + '/publish').set(true);
  }

  unpublish(item) {
   this.db.database.ref(this.mainDB + '/produk/' + item.id + '/publish').set(false);
  }

  getRecord(row) {
    this.dialogRef = this.dialog.open(DialogInputProduk, {
      height: '90%',
      width: '50%',
      data: {
        'type': 'edit',
        'data': row,
        'idkategori': this.data.id,
        'nmkategori': this.data.category,
      },
    });
  }

  addProduct() {
    this.dialogRef = this.dialog.open(DialogInputProduk, {
      height: '90%',
      width: '50%',
      data: {
        'type': 'baru',
        'idkategori': this.data.id,
        'nmkategori': this.data.category,
      },
    });
  }
}

@Component({
  selector: 'daftarmenu-dialog',
  templateUrl: 'daftarmenu-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogDaftarmenu {
  uploadPercent: Observable<number>;

  downloadURL: Observable<string>;
  gambar: any;
  kategori: any;
  oldGambar: any;
  flag: boolean = true;
  key: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDB = environment.mainDb;
  constructor(
    public dialogRef: MatDialogRef<DialogDaftarmenu>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  ngOnInit() {
    if (this.data !== null) {
      this.flag = true;
      this.gambar = this.data.image;
      this.key = this.data.id;
      this.oldGambar = this.data.filename;
      this.kategori = this.data.category;
    } else {
      this.flag = false;
      this.gambar = 'https://firebasestorage.googleapis.com/v0/b/pilihmanasports.appspot.com/o/empty.png?alt=media&token=a2749e66-780c-4e91-ad4c-689cbf041c8a';
      this.kategori = '';
    }

  }

  openfile() {
    jQuery('#imgupload').trigger('click');
  }


  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  async editCategory(event) {
    if (this.gambar === '') {
      alert('Gambar belum dipilih !!!');
      return;
    }
    if (this.kategori === '') {
      alert('Kategori harus di isi !!!');
      return;
    }


    this.loadSpinner();


    // observe percentage changes
    if (this.flag) {

      if (this.croppedImage !== '') {
        this.storage.ref(this.data.filename).delete();
        // this.storage.ref(this.oldGambar).delete();
        const file = this.croppedImage;
        const filePath = this.kategori + '.webp';
        const fileRefr = this.storage.ref(filePath.toString());
        const task = this.storage.upload(filePath, file.file);
        task.snapshotChanges().pipe(
          finalize(() => {
            this.downloadURL = fileRefr.getDownloadURL()
            this.downloadURL.subscribe(url => {
              if (url) {
                this.db.database.ref(this.mainDB + '/kategori/' + this.key + '/').set(
                  {
                    'nama': this.kategori.toString(),
                    'filename': filePath.toString(),
                    'image': url,
                    'publish': true,
                    'produk': this.data.produk === undefined ? "" : this.data.produk,
                  }
                ).then(() => {
                  // this.storage.ref(this.data.image).delete();
                  this.closeSpinner();
                  this.dialogRef.close();
                })
              }
            })
          })
        ).subscribe();
      } else {
        this.db.database.ref(this.mainDB + '/kategori/' + this.key + '/').set(
          {
            'nama': this.kategori.toString(),
            'filename': this.data.filename,
            'image': this.data.image,
            'publish': true,
            'produk': this.data.produk === undefined ? "" : this.data.produk,
          }
        ).then(() => {
          this.closeSpinner();
          this.dialogRef.close();
        })
      }
    } else {
      const file = this.croppedImage;
      const filePath = this.kategori + '.webp';
      const fileRefr = this.storage.ref(filePath.toString());
      const task = this.storage.upload(filePath, file.file);
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRefr.getDownloadURL()
          this.downloadURL.subscribe(url => {
            if (url) {
              this.db.database.ref(this.mainDB + '/kategori/').push(
                {
                  'nama': this.kategori.toString(),
                  'filename': filePath.toString(),
                  'image': url,
                  'publish': true,
                  'produk': ""
                }
              ).then(() => {
                this.closeSpinner();
                this.dialogRef.close();
              })
            }
          })
        })
      )
        .subscribe()
    }
  }

  getFileAttachment(event: any): void {
    this.imageChangedEvent = event;
  }




  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this.gambar = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}

function GetListMenu(category: string, id: string, image: string, filename: string, produk: any, publish: boolean): MenuData {

  return {
    id: id.toString(),
    category: category,
    image: image,
    filename: filename,
    produk: produk,
    publish: publish,
  };
}

export interface MenuData {
  id: string;
  category: string;
  image: string;
  filename: string;
  produk: any;
  publish: boolean;

}


export interface ProdukData {
  id: string;
  nama: string;
  sub: string;
  keterangan: string;
  ingredients: string;
  image: any;
  satuan: string;
  ukuran: string;
  harga: number;
  hargaReseller: number;
  disc: number;
  publish: any;
  stock: number;
  supplier: string;
  ukuranKurir: number;
  tag: TagProduk[];
  contentCategory: CategoryModel;
  subCategory: SubCategory;
  sex: string;
  arrival?: boolean;
}
export interface Food {
  value: string;
  viewValue: string;
}

export interface Satuan {
  value: string;
  viewValue: string;
}

export interface iSupplier {
  key?: string;
  kontak?: string;
  nama?: string;
  alamat?: string;
}

function GetSatuan(value: string, viewValue: string): Satuan {
  return {
    value: value,
    viewValue: viewValue,
  };
}
export const COMPONENT_LIST = [
  DaftarMenu,
  DialogDaftarmenu,
  DialogDaftarProduk,
  DialogInputProduk,
  DialogProdukImage

]

import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'environments/environment';
import { HelpModel } from '../pengaturan.component';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  listHelp: any = [];
  mainDB = environment.mainDb;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  constructor(
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<HelpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.listHelp = this.data;
    // this.loadbank();
  }

  tambah() {
    const dialogref = this.dialog.open(DialogTambahHelp, {
      panelClass: 'dialog',
      hasBackdrop: true,
      width: '40%',
      // height: '70%',
    });

    dialogref.afterClosed().subscribe(result => {
      // if (result !== '') {
      //   console.log(result);
      //   this.Modelbank.push({ Nama: result.Nama, atasNama: result.atasNama, image: result.image, rekening: result.rekening });
      // }
    });
  }

  async onRemove(e) {
    // console.log(e);
    // this.Modelbank.splice(i, 1);
    console.log(e)
    var r = confirm("Yakin ingin menghapus " + e.Nama + " ?");
    if (r == true) {
      this.loadSpinner();
      await this.db.database.ref(this.mainDB + 'settings/help/' + e.id).remove();
      this.closeSpinner();
      this.dialogRef.close();
    }
  }

  onEdit(data) {
    // this.dialogRef.close(this.Modelbank);
    this.loadSpinner();
    this.db.database.ref(this.mainDB + 'settings/help/' + data.id).set({
      'Nama': data.Nama,
      'Materi': data.Materi,
    }).then(() => {
      this.closeSpinner();
      this.dialogRef.close();
    });

  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

}



@Component({
  selector: 'edithelpdialog',
  templateUrl: './tambahhelp/tambahHelp.html',
  styleUrls: ['../bank/bank-dialog.scss']
})
export class DialogTambahHelp {
  ModelHelp: any = [];
  imageChangedEvent: any = '';
  gambar: any;
  file: any;

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDB = environment.mainDb;
  constructor(
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogTambahHelp>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

  }
  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  onSave() {

    this.loadSpinner();
    var currentUnixTime = Date.now();
    this.db.database.ref(this.mainDB + 'settings/help/' + currentUnixTime + '/').set(
      {
        'Nama': this.ModelHelp.Nama,
        'Materi': this.ModelHelp.Materi,
      }).finally(() => {
        this.closeSpinner();
        this.dialogRef.close();
      })
  }
}
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FaqService {
  itemsRef: AngularFireList<FaqModel>;
  items: Observable<FaqModel[]>;
  mainDB = environment.mainDb;
  constructor(private db: AngularFireDatabase) {
    this.itemsRef = db.list(this.mainDB + 'faq');
    this.items = this.itemsRef.snapshotChanges()
      .pipe(map(change => {
        return change.map(c => ({ key: c.payload.key, ...c.payload.val() }));
      }))
  }

  getItems(): Observable<any> {
    return this.items
  }

  postItems(data: FaqModel): Promise<boolean> {
    let ref = this.db.database.ref(this.mainDB + 'faq').push();

    const faqData: FaqModel = {
      'Nama': data.Nama,
      'Materi': data.Materi,
      'Time': new Date(),
    }

    return ref
      .set(faqData).then(() => {
        return true
      })
      .catch(
        (error: any) => {
          console.log("Create Message Failed");
          console.error(error);
          console.log("Transfer Object:");
          return false;
        });
  }

  updateItems(data: FaqModel): Promise<boolean> {
    let ref = this.db.database.ref(this.mainDB + 'faq/' + `${data.id}`);

    const faqData: FaqModel = {
      'Nama': data.Nama,
      'Materi': data.Materi,
      'Time': new Date(),
    }
    return ref
      .set(faqData).then(() => {
        return true;
      })
      .catch(
        (error: any) => {
          console.log("Create Message Failed");
          console.error(error);
          console.log("Transfer Object:");
          // console.dir(supplierItem);
          return false;
        });
  }

  deleteItems(id: string): Promise<boolean> {
    return this.db.database.ref(this.mainDB + 'faq/' + `${id}`)
      .remove().then(() => {
        return true;
      })
      .catch(
        (error: any) => {
          console.log("Delete Message Failed");
          console.error(error);
          console.log("key:", id);
          return false;
        }
      )
  }
}

export interface FaqModel {
  id?: string;
  Nama?: string;
  Materi?: string;
  Time?: any;
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  BaseUrl: 'https://fcm.googleapis.com/fcm/send',
  rajaApi: "https://x.rajaapi.com/",
  apiToken: "https://x.rajaapi.com/poe",
  mainDb: '/debug/',
  fDb: '/debug/0/',
  serverKey: 'key=AAAAzHGpkK4:APA91bFTODv7jiDu9b703GFERuxCW22F5AC4JfcmLuu6WknGK6B9qyQ8NrNbGdcUrkLE3ND76hTog-kFbnOhHEu-ck8aqXnQaUX46AxOIXu0Eb_wm9mz_rLJ8oXBWT2XIMDglPFs_R5n',
  firebaseConfig: {
    apiKey: "AIzaSyDp2-NWzDPyBqzyGRdHpdUtlvc9RqOb8-0",
    authDomain: "pilihmanasports.firebaseapp.com",
    databaseURL: "https://pilihmanasports.firebaseio.com",
    projectId: "pilihmanasports",
    storageBucket: "pilihmanasports.appspot.com",
    messagingSenderId: "878080266414",
    appId: "1:878080266414:web:b1e3883771dfcd87ef1d3f",
    measurementId: "G-78PC4YLH6W"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryModel, TagContent } from './model.component';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  itemsRef: AngularFireList<CategoryModel>;
  items: Observable<CategoryModel[]>;
  mainDB = environment.mainDb;
  constructor(
    public storage: AngularFireStorage,
    private db: AngularFireDatabase) {
    this.itemsRef = db.list(this.mainDB + 'categoryDisplay');
    this.items = this.itemsRef.snapshotChanges()
      .pipe(map(change => {
        return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      }))
  }

  getItems(): Observable<any> {
    return this.items
  }

  async postKasus(data: CategoryModel, filePath, file, name: string, tag: TagContent[]): Promise<boolean> {
    let hasil2: boolean;
    let url: string;
    let refsatu = this.db.database.ref(this.mainDB + 'categoryDisplay').push();
    const task = this.storage.upload(filePath, file.file);
    await (await task).ref.getDownloadURL().then(async (res): Promise<boolean> => {
      const category: CategoryModel = {
        nama: data.nama !== undefined ? data.nama : '',
        image: res,
        filename: name,
        tag: tag,
        publish: data.publish,
      }
      await refsatu
        .set(category).then((res) => {
          hasil2 = true;
          return hasil2;
        })
        .catch(
          (error: any) => {
            hasil2 = false;
            return hasil2;
            // console.dir(supplierItem);
          });
      return hasil2;
    });
    return hasil2;
  }

  async putKasus(data: CategoryModel, file, tag: TagContent[]): Promise<boolean> {
    let hasil: boolean;
    let url: string;
    let ref = this.db.database.ref(this.mainDB + 'categoryDisplay/' + data.id);
    const task = this.storage.ref('display/' + data.filename).put(file.file);
    await (await task).ref.getDownloadURL().then(async (res): Promise<any> => {
      const category: CategoryModel = {
        nama: data.nama !== undefined ? data.nama : '',
        image: res,
        filename: data.filename,
        tag: tag,
        publish: data.publish,
      }
      await ref
        .set(category).then((res) => {
          return hasil = true;
        })
        .catch(
          (error: any) => {
            return hasil = false;
            // console.dir(supplierItem);
          });
      return hasil;
    });
    return hasil;
  }

  async putKasusNo(data: CategoryModel,): Promise<boolean> {
    let hasil1: boolean;
    let url: string;
    let refdua = this.db.database.ref(this.mainDB + 'categoryDisplay/' + data.id);
    const category: CategoryModel = {
      nama: data.nama !== undefined ? data.nama : '',
      image: data.image,
      filename: data.filename,
      tag: data.tag,
      publish: data.publish,
    }
    await refdua
      .set(category).then((res) => {
        hasil1 = true;
        return hasil1;
      })
      .catch(
        (error: any) => {
          hasil1 = false;
          return hasil1;
          // console.dir(supplierItem);
        });
    return hasil1;
  }
  async delete(item: CategoryModel) {
    await this.db.database.ref(this.mainDB + '/categoryDisplay/' + item.id).remove();
    await this.storage.ref('display/' + item.filename).delete();
    return true;
  }
}

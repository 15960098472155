import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Howl, Howler } from 'howler';
import { ToastrService } from 'ngx-toastr';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { environment } from 'environments/environment';
@Injectable()
export class MessagingService {
    // Retrieve Firebase Messaging object.
    mainDB = environment.mainDb;
    currentMessage = new BehaviorSubject(null);
    itemsRef: AngularFireList<any[]>;
    items: Observable<any[]>;
    constructor(
        private db: AngularFireDatabase,
        private toastr: ToastrService,
        private _fuseNavigationService: FuseNavigationService,
        private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messaging.subscribe(
            (_messaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
        this.itemsRef = db.list(this.mainDB + 'Penjualan');
    }
    requestPermission() {
        let fcm = [];
        item: Boolean;
        this.angularFireMessaging.requestToken.subscribe(
            (res) => {
                localStorage.setItem("FCM", res);
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                // this.itemsRef.query.once("value").then((data) => {
                //     console.log(data.key)
                // });
                // // .pipe(map(change => {
                // //     return change.map(c => ({ key: c.payload.key, ...c.payload.val() }));
                // // }))
                this._fuseNavigationService.updateNavigationItem('order', {
                    badge: {
                        title: 1,
                        bg: '#F44336',
                        fg: '#FFFFFF'
                    }
                });
                var sound = new Howl({
                    src: ['assets/music/eventually.mp3']
                });
                sound.play();
                this.toastr.info(payload['notification']['body'], payload['notification']['title'], {
                    timeOut: 3000,
                    positionClass: 'toast-bottom-right'
                });
                this.currentMessage.next(payload);
            })
    }
}
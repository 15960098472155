import { FuseNavigation } from '@fuse/types';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'category',
                title: 'Category',
                translate: 'NAV.CONTENT.TITLE',
                type: 'item',
                icon: 'list',
                url: '/categoryproduk',
                // badge    : {
                //     title    : '25',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
            },
            {
                id: 'menu',
                title: 'Product',
                translate: 'NAV.DAFTARMENU.TITLE',
                type: 'item',
                icon: 'list',
                url: '/category',
                // badge    : {
                //     title    : '25',
                //     translate: 'NAV.DETAIL.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }

            },
            {
                id: 'slide',
                title: 'Slide',
                translate: 'NAV.SLIDE.TITLE',
                type: 'item',
                icon: 'list',
                url: '/slide',
                // badge    : {
                //     title    : '1',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }

            },
            {
                id: 'order',
                title: 'Order',
                translate: 'NAV.ORDER.TITLE',
                type: 'item',
                icon: 'list',
                url: '/order',
                // badge: {
                //     title: '0',
                //     translate: 'NAV.ORDER.BADGE',
                //     bg: '#F44336',
                //     fg: '#FFFFFF'
                // }

            },
            // {
            //     id: 'orderreseller',
            //     title: 'Reseller Order',
            //     translate: 'NAV.RORDER.TITLE',
            //     type: 'item',
            //     icon: 'list',
            //     url: '/resellerorder',
            //     // badge    : {
            //     //     title    : '1',
            //     //     translate: 'NAV.SAMPLE.BADGE',
            //     //     bg       : '#F44336',
            //     //     fg       : '#FFFFFF'
            //     // }

            // },
            {
                id: 'kurirlist',
                title: 'Kurir',
                translate: 'NAV.LISTKURIR.TITLE',
                type: 'item',
                icon: 'list',
                url: '/kurir-list',
            },
            {
                id: 'supplier',
                title: 'Supplier',
                translate: 'NAV.SUPPLIER.TITLE',
                type: 'item',
                icon: 'list',
                url: '/supplier',
            },
            // {
            //     id: 'sup',
            //     title: 'Reseller',
            //     translate: 'NAV.RESELLER.TITLE',
            //     type: 'item',
            //     icon: 'list',
            //     url: '/reseller',
            // },
            {
                id: 'daftarkonsumen',
                title: 'Pelanggan',
                translate: 'NAV.KONSUMEN.TITLE',
                type: 'item',
                icon: 'list',
                url: '/daftar-konsumen',
            },
            {
                id: 'laporan',
                title: 'Laporan',
                translate: 'NAV.LAPORAN.TITLE',
                type: 'item',
                icon: 'list',
                url: '/laporan',
                // badge    : {
                //     title    : '1',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
            },
            {
                id: 'pengaturan',
                title: 'Pengaturan',
                translate: 'NAV.PENGATURAN.TITLE',
                type: 'collapsable',
                icon: 'list',
                children: [
                    // {
                    //     id: 'faq',
                    //     title: 'Faq',
                    //     translate: 'NAV.FAQ.TITLE',
                    //     type: 'item',
                    //     url: '/faq',
                    // },
                    {
                        id: 'pengaturan',
                        title: 'Pengaturan',
                        translate: 'NAV.PENGATURAN1.TITLE',
                        type: 'item',
                        url: '/pengaturan',
                    },
                    {
                        id: 'mastersatuan',
                        title: 'Master Satuan',
                        translate: 'NAV.MASTERSATUAN.TITLE',
                        type: 'item',
                        url: '/settings/master-satuan',
                    },
                    {
                        id: 'daftarharga',
                        title: 'Daftar Harga',
                        translate: 'NAV.DAFTARHARGA.TITLE',
                        type: 'item',
                        url: '/daftar-harga',
                    },
                ]
            },
            {
                id: 'blog',
                title: 'Blog Writing',
                translate: 'NAV.BLOG.TITLE',
                type: 'item',
                icon: 'list',
                url: '/writing',
            },
            {
                id: 'notifkasi',
                title: 'Push Notifikasi',
                translate: 'NAV.NOTIF.TITLE',
                type: 'item',
                icon: 'list',
                url: '/push-notifikasi',
            },
            {
                id: 'keluar',
                title: 'Keluar',
                translate: 'NAV.KELUAR.TITLE',
                type: 'item',
                icon: 'settings_power',
                url: '/login',
                function: () => {
                    localStorage.clear();
                    // this.AngularFireAuth.auth.signOut().then(() => {
                    //     this.router.navigate(['/login']);
                    // });
                },
                // badge    : {
                //     title    : '1',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
            },
        ]
    }

];

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../service/api.service';
import { SubCategory } from '../service/model';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {
  ListCat: SubCategory[] = [];
  private Unsubscribe = new Subject();
  categoryForm: FormGroup;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  constructor(
    public dbService: ApiService,
    public dialogRef: MatDialogRef<AddCategoryComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.categoryForm = new FormGroup({
      nama: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.dbService.getCat()
      .pipe(
        takeUntil(this.Unsubscribe)
      ).subscribe((result) => {
        this.ListCat = result;
      })
  }
  RemoveMore(index) {
    this.ListCat.splice(index, 1);
    // console.log(this.ListImageEdit);
  }
  addCategory() {
    this.ListCat.push({ nama: this.categoryForm.get('nama').value })
    this.categoryForm.reset();
  }
  saveCategory() {
    this.spinnerWithoutBackdrop = true;
    this.dbService.simpanCat(this.ListCat).then((res) => {
      this.spinnerWithoutBackdrop = false;
    }).catch((err) => {
      this.spinnerWithoutBackdrop = false;
    })
  }
}

import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
//import { DOCUMENT } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { FormControl, Validators, Form, NgForm } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
declare var jQuery: any;


@Component({
  selector: 'slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class DaftarSlide {
  ref: any = [];
  items: any[];
  selectedRowIndex = [];
  loadRef: any = [];
  dialogRef: any = [];
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDB = environment.mainDb;
  listmenu: SlideData[] = [];
  listCategory: any = [];
  dataSource: MatTableDataSource<SlideData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private router: Router,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }
  async ngOnInit() {
    let auth = await localStorage.getItem("AUTH");
    if (auth === null) {
      this.router.navigate(['/login']);
    }
    this.loadSpinner();
    this.db.list(this.mainDB + '/slide')
      .snapshotChanges()
      .subscribe(result => {
        this.listmenu = [];
        result.forEach(item => {
          this.listmenu.push(
            GetListSlide(item.key, item.payload.val()['image'], item.payload.val()['nama'],
              item.payload.val()['deskripsi'], item.payload.val()['type'], item.payload.val()['video'], item.payload.val()['videourl'])
          )
        });
        this.dataSource = new MatTableDataSource(this.listmenu);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.closeSpinner();
      });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  delRecord(item) {
    var r = confirm("Yakin ingin menghapus ?");
    if (r == true) {
      this.loadSpinner();
      this.db.database.ref(this.mainDB + '/slide/' + item.id).remove();
      item.image.forEach((item) => {
        var str = item.url;
        var pecah = str.split("?");
        var pecah2 = pecah[0].split("%2F");
        this.storage.ref(pecah2).delete();
      });
    }
  }

  getRecord(row) {
    this.dialogRef = this.dialog.open(DialogInputSlide, {
      data: { 'produk': row, category: this.listCategory },
      height: '90%',
      width: '40%',
    });
  }


  addProduct() {
    this.dialogRef = this.dialog.open(DialogInputSlide, {
      // height: '95%',
      width: '45%',
      data: { 'produk': null, category: this.listCategory },
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

@Component({
  selector: 'daftarharga-dialog',
  templateUrl: 'daftarharga-dialog.html',
  styleUrls: ['slide.component.scss']
})
export class DialogDaftarharga {

  flag: boolean = true;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  harga: any = [];
  spinnerWithoutBackdrop = false;

  satuan: Satuan[] = [
    { value: 'Kg', viewValue: 'Kg' },
    { value: 'gr', viewValue: 'Gram' },
    { value: 'ons', viewValue: 'ons' },
    { value: 'Ikat', viewValue: 'Ikat' },
    { value: 'ml', viewValue: 'Mili Liter' },
    { value: 'L', viewValue: 'Liter' },
    { value: 'Lusin', viewValue: 'Lusin' },
    { value: 'Botol', viewValue: 'Botol' },
    { value: 'Kotak', viewValue: 'Kotak' }
  ];

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }

  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }
}

@Component({
  selector: 'inputProdukImage-dialog',
  templateUrl: 'inputProdukImage-dialog.html',
  styleUrls: ['slide.component.scss']
})
export class DialogProdukImage {
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  loadedIMG: boolean = false;
  modelProduct: any = [];
  ListImage = [];
  imageChangedEvent: any = '';
  gambar: any;
  croppedImage: any = '';
  file: any;
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,

    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;
  ngOnInit() {
    if (this.data !== null) {
      this.gambar = this.data.image;
    }
  }
  // openfile() {
  //   jQuery('#imgupload').trigger('click');
  // }
  // getFileAttachment(event: any) {
  //   this.loadedIMG = true;
  //   this.imageChangedEvent = event;
  // }

  openfile() {
    jQuery('#imgupload').trigger('click');
  }

  getFileAttachment(event: any) {
    this.file = '';
    // this.imageChangedEvent = event;
    // console.log(event);
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.gambar = reader.result;

      reader.readAsDataURL(this.file);
    }
  }

  imageCropped(event: ImageCroppedEvent) {

    this.croppedImage = event;
    this.gambar = event.base64;
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}


@Component({
  selector: 'inputSlide-dialog',
  templateUrl: 'inputSlide-dialog.html',
  styleUrls: ['slide.component.scss']
})
export class DialogInputSlide {
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  donwloadLink: any = [];
  // modelSlide: any = [];
  kategori: any;
  kategoriKey: string;
  flag: boolean = true;
  flagedit: boolean = false;
  key: any;
  imageChangedEvent: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  modelProduct: any = [];
  ListImage: any = [];
  ListImageEdit: any = [];
  ListMenu: any = [];
  ListHarga: any = [];
  ListImageAsal: any = [];
  namaKategori;
  kategoriControl: FormControl = new FormControl();
  mainDB = environment.mainDb;
  constructor(
    private storage: AngularFireStorage,
    public dialogInput: MatDialogRef<DialogInputSlide>,
    public dialogInputharga: MatDialogRef<DialogDaftarharga>,
    public dialogRef: MatDialogRef<DialogProdukImage>,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    iconRegistry.addSvgIcon(
      'photo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/add_photo.svg'));
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg'));
  }

  ngOnInit() {
    if (this.data.produk != null) {
      this.flag = false;
      this.flagedit = true;
      if (this.data.produk.image !== undefined) {
        this.data.produk.image.forEach((item) => {
          this.ListImage.push({
            url: item.url,
            asal: 1,
          });
        });
      }


      this.ListHarga = this.data.produk.harga;
      this.modelProduct = this.data.produk;
      // this.modelSlide = this.data.produk;

    }
  }

  openfile(url) {
    window.open(url);
  }

  RemoveMore(index, item) {
    this.ListImage.splice(index, 1);
    var str = item.url;
    var pecah = str.split("?");
    var pecah2 = pecah[0].split("%2F");
    this.ListImageEdit.push(pecah2[1]);
  }

  Removeharga(index) {
    this.ListHarga.splice(index, 1);
  }
  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  clickNew() {
    this.dialogRef = this.dialog.open(DialogProdukImage, {
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== '') {
        let gbr = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          + result.base64);
        this.ListImage.push({ asal: 0, file: result.file, url: result.blob });
        this.ListImageAsal.push(result.file);
      }

    });
  }

  async delImage() {
    if (this.flagedit) {
      this.ListImageEdit.forEach((item) => {
        this.storage.ref(this.namaKategori + '/' + item).delete();
      });
      return true;
    } else {
      return true;
    }
  }

  async getCategory(kunci) {
    this.kategori.forEach((key, value) => {
      if (key['id'] == kunci) {
        this.namaKategori = key['category'];
      }
    })
  }

  async getImage() {
    if (this.flagedit) {
      this.ListImage.forEach((item) => {
        if (item.asal == 1) {
          this.donwloadLink.push({
            url: item.url,
          });
        }
      });
    }

  }

  addHarga() {
    this.dialogInputharga = this.dialog.open(DialogDaftarharga, {
      width: '15%',
    });
    this.dialogInputharga.afterClosed().subscribe(result => {
      if (result !== '') {
        this.ListHarga.push({ ukuran: result.ukuran, ukuranSampai: result.ukuranSampai, satuan: result.satuan, nominal: result.nominal });
      }
    });
  }

  cek() {
    console.log(this.ListImage);
  }

  async editCategory() {

    if (this.ListImage.length < 1 && this.modelProduct.videurl == '') {
      alert('Gambar atau video harus di isi ..');
      return
    }
    if (this.modelProduct.nama == '') {
      alert('Nama slide tidak boleh kosong ..');
      return
    }
    if (this.modelProduct.deskripsi == '') {
      alert('Deskripsi slide tidak boleh kosong ..');
      return
    }

    //var lVideo = this.modelProduct.videourl !== '' ?  : '';
    await this.delImage();
    await this.getImage();
    this.loadSpinner();
    var currentUnixTime = Date.now();
    const allPercentage: Observable<number>[] = [];
    if (this.ListImageAsal.length > 0) {
      for (const { item, index } of this.ListImage.map((item, index) => ({ item, index }))) {
        // console.log(item);
        const filePath = 'slide/' + currentUnixTime + '_' + index;
        const namaimage = currentUnixTime + '_' + index;
        const fileRef = this.storage.ref(filePath);
        if (item.asal == 0) {
          const task = this.storage.upload(filePath, item.file);
          const _percentage$ = task.percentageChanges();
          allPercentage.push(_percentage$);

          // observe percentage changes
          this.uploadPercent = task.percentageChanges();

          // get notified when the download URL is available
          task.snapshotChanges().pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe(url => {
                var kunci = this.flagedit ? this.modelProduct.id : currentUnixTime;
                this.donwloadLink.push({ 'url': url });
                if (this.ListImage.length === this.donwloadLink.length) {
                  this.db.database.ref(this.mainDB + '/slide/' + kunci).set(
                    {
                      'image': this.donwloadLink,
                      'type': 'slide',
                      'nama': this.modelProduct.nama !== '' ? this.modelProduct.nama : "",
                      'video': this.modelProduct.videourl !== '' ? this.modelProduct.videourl.split("=")[1] : "",
                      'videourl': this.modelProduct.videourl !== '' ? this.modelProduct.videourl : "",
                      'deskripsi': this.modelProduct.deskripsi !== '' ? this.modelProduct.deskripsi : "",
                    }
                  ).then(() => {
                    this.closeSpinner();
                    this.dialogInput.close();
                  })
                }
              }
              );
            })
          ).subscribe();
        }
      }
    } else {
      var kunci = this.flagedit ? this.modelProduct.id : currentUnixTime;
      this.db.database.ref(this.mainDB + '/slide/' + kunci).set(
        {
          'image': this.donwloadLink,
          'type': 'slide',
          'nama': this.modelProduct.nama !== '' ? this.modelProduct.nama : "",
          'video': this.modelProduct.videourl !== '' ? this.modelProduct.videourl.split("=")[1] : "",
          'videourl': this.modelProduct.videourl !== '' ? this.modelProduct.videourl : "",
          'deskripsi': this.modelProduct.deskripsi !== '' ? this.modelProduct.deskripsi : "",
        }
      ).then(() => {
        this.closeSpinner();
        // if(this.oldGambar !== filePath){
        // this.storage.ref(this.oldGambar).delete(); 
        // }  
        this.dialogInput.close();
      })
    }
  }

}

@Component({
  selector: 'slide-dialog',
  templateUrl: 'slide-dialog.html',
  styleUrls: ['slide.component.scss']
})
export class DialogDaftarSlide {
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  gambar: any;
  kategori: any;
  oldGambar: any;
  flag: boolean = true;
  key: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDB = environment.mainDb;
  constructor(
    public dialogRef: MatDialogRef<DialogDaftarSlide>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  ngOnInit() {
    if (this.data !== null) {
      this.flag = true;
      this.gambar = this.data.image;
      this.key = this.data.id;
      this.oldGambar = this.data.filename;
      this.kategori = this.data.category;

    } else {
      this.flag = false;
      this.gambar = 'https://firebasestorage.googleapis.com/v0/b/pasarpagisegar.appspot.com/o/empty.png?alt=media&token=3e5b1264-f831-4c28-9d61-2956fbcf1a42';
      this.kategori = '';
    }

  }

  openfile() {
    jQuery('#imgupload').trigger('click');
  }


  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  editCategory() {
    if (this.croppedImage === '') {
      alert('Gambar belum dipilih !!!');
      return;
    }
    if (this.kategori === '') {
      alert('Kategori harus di isi !!!');
      return;
    }


    this.loadSpinner();
    const file = this.croppedImage;
    const filePath = this.kategori + '.webp';

    // observe percentage changes
    if (this.flag) {
      const fileRef = this.storage.ref(filePath);
      const task = fileRef.put(this.croppedImage);
      this.downloadURL = fileRef.getDownloadURL();
      this.downloadURL.subscribe(url => {
        if (url) {
          this.db.database.ref(this.mainDB + '/kategori/' + this.key + '/').set(
            {
              'nama': this.kategori.toString(),
              'filename': filePath.toString(),
              'image': url,
            }
          ).then(() => {
            this.closeSpinner();
            if (this.oldGambar !== filePath) {
              this.storage.ref(this.oldGambar).delete();
            }
            this.dialogRef.close();
          })
        }
      })
    } else {
      const fileRefr = this.storage.ref(filePath.toString());
      const task = this.storage.upload(filePath, this.croppedImage);
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRefr.getDownloadURL()
          this.downloadURL.subscribe(url => {
            if (url) {
              this.db.database.ref(this.mainDB + '/kategori/').push(
                {
                  'nama': this.kategori.toString(),
                  'filename': filePath.toString(),
                  'image': url,
                }
              ).then(() => {
                this.closeSpinner();
                this.dialogRef.close();
              })
            }
          })
        })
      )
        .subscribe()
    }
  }

  getFileAttachment(event: any) {
    this.imageChangedEvent = event;
    //   if (event.target.files && event.target.files[0]) {
    //     const file = event.target.files[0];

    //     const reader = new FileReader();
    //     reader.onload = e => this.gambar = reader.result;
    //     reader.readAsDataURL(file);
    // }
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.file;
    this.gambar = event.base64;

  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }


}


function GetListSlide(id: string, image: any, nama: string, deskripsi: string,
  type: string, video: string, videourl: string): SlideData {
  return {
    id: id,
    image: image,
    nama: nama,
    video: video,
    videourl: videourl,
    deskripsi: deskripsi,
    type: type,
  };
}

export interface SlideData {
  id: string,
  image: any[];
  nama: string;
  deskripsi: string;
  video: string;
  videourl: string;
  type: string;
}


export interface Food {
  value: string;
  viewValue: string;
}
export interface Satuan {
  value: string;
  viewValue: string;
}

export const COMPONENT_LIST = [
  DaftarSlide,
  DialogDaftarSlide,
  DialogInputSlide,
  DialogDaftarharga,
  DialogProdukImage,
]

import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import * as internal from 'assert';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  itemsRef: AngularFireList<ProdukData>;
  items: Observable<ProdukData[]>;
  mainDB = environment.mainDb;
  constructor(
    public storage: AngularFireStorage,
    private db: AngularFireDatabase) {

  }

  getItems(): Observable<ProdukData[]> {
    this.itemsRef = this.db.list(this.mainDB + 'Penjualan/');
    this.items = this.itemsRef.snapshotChanges()
      .pipe(map(change => {
        return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      }))
    return this.items;
  }

  getItemsAdmins(): Observable<ProdukData[]> {
    this.itemsRef = this.db.list(this.mainDB + 'kategori');
    this.items = this.itemsRef.snapshotChanges()
      .pipe(map(change => {
        return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      }))
    return this.items;
  }

  
}
export interface ProdukData {
  id:string;
  idSupplier: string;
  status: string;
  statusBayar: string;
  tokenFcm: string;
  nama: string;
  uid: string;
  total: number;
  dataSupplier: dataSupplier;
  tanggal: Date;
  pengiriman: dataPengiriman;
  kurir: dataKurir;
  chart: dataChart;
  kurirstatus:string;
}

export class dataPengiriman {
  namaAlamat: string;
  alamat: string;
  kecamatan: string;
  kelurahan: string;
  kota: string;
  kodePos: string;
  latitude: any;
  longitude: any;
}

export class dataSupplier {
  alamat: string;
  email: string;
  kecamatan: string;
  kelurahan: string;
  nama:string;
  kota: string;
  latitude: string;
  longitude: string;
  photoUrl: string;
  provinsi: string;
}

export class dataKurir {
  biayaKirim: number;
  jarak: number;
  estimasi:string;
  kontak:string;
  nama:string;
  noResi:string;
  vendorkurir:string;
}
export class dataChart{

}



import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddBlogComponent } from './add/add-blog.component';
import { AddCategoryComponent } from './category/add-category.component';
import { } from './service/model';
import { ApiService } from './service/api.service';
import { fuseAnimations } from '@fuse/animations';
import { SubCategory } from '../daftarmenu/addsub/model';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  animations: fuseAnimations,
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  private Unsubscribe = new Subject();
  filteredOptions: Observable<any>;
  ListCategory: SubCategory[] = [];
  mainDB = environment.mainDb;
  croppedImage: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  displayedColumns = ['no', 'judul', 'kategori', 'isi'];
  dataBlog: MatTableDataSource<any>;
  obs: Observable<any>;
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  showTab: string = 'blog';
  constructor(
    private db: AngularFireDatabase,
    public dbService: ApiService,
    public dialog: MatDialog,
    //public dialogRef: MatDialogRef<AddcategoryComponent>,
  ) { }

  ngOnInit() {
    this.dbService.getItems()
      .pipe(
        takeUntil(this.Unsubscribe)
      ).subscribe((res) => {
        this.dataBlog = new MatTableDataSource(res)
        this.dataBlog.paginator = this.paginator
        this.dataBlog.sort = this.sort
      })
    this.dbService.getCat().subscribe((res) => {
      this.ListCategory = res;
    });
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataBlog.filter = filterValue;
    if (this.dataBlog.paginator) {
      this.dataBlog.paginator.firstPage();
    }
  }

  async delRecord(item) {
    var r = confirm("Yakin ingin menghapus " + item.judul + " ?");
    if (r === true) {
      this.spinnerWithoutBackdrop = true;
      this.dbService.delete(item).then((res) => {
        this.spinnerWithoutBackdrop = false;
      }).catch((er) => {
        this.spinnerWithoutBackdrop = false;
      });
    }
  }
  getRecord(item) {
    const dialogRef = this.dialog.open(AddBlogComponent, {
      panelClass: 'app-addBlog',
      hasBackdrop: true,
      data: {
        action: 'edit',
        row: item
      }
    });
  }
  addblog() {
    const dialogRef = this.dialog.open(AddBlogComponent, {
      panelClass: 'app-addBlog',
      hasBackdrop: true,
      data: {
        action: 'new',
      }
    });
    dialogRef.afterClosed().subscribe((res) => {

    });
  }

  getCat(item) {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      panelClass: 'app-addBlog',
      hasBackdrop: true,
      data: {
        action: 'edit',
        row: item
      }
    });
  }
  addCat() {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      panelClass: 'app-addBlog',
      hasBackdrop: true,
      data: {
        action: 'new',
      }
    });
    dialogRef.afterClosed().subscribe((res) => {

    });
  }


  publish(item) {
    this.db.database.ref(this.mainDB + 'blog/' + item.id + '/publish').set(true);
  }

  unpublish(item) {
    this.db.database.ref(this.mainDB + 'blog/' + item.id + '/publish').set(false);
  }

  show(item) {
    if (item.index === 0) {
      this.showTab = 'blog'
      return;
    }
    if (item.index === 1) {
      this.showTab = 'kategori'
      return;
    }
  }

}

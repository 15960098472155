import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../services/api.service';
@Component({
  selector: 'app-notifikasi',
  templateUrl: './notifikasi.component.html',
  styleUrls: ['./notifikasi.component.scss']
})
export class NotifikasiComponent implements OnInit {
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  clientToken: any = [];
  spinnerWithoutBackdrop = false;
  mainDB = environment.mainDb;
  auth: any;
  msg = {
    "notification": {
      // "image": "https://firebasestorage.googleapis.com/v0/b/royyan-48da2.appspot.com/o/mbb.png?alt=media&token=4bbc5ed4-bbc8-45a3-8b6a-9c0d76a21511",
      "color": "#990000",
      "click_action": "FLUTTER_NOTIFICATION_CLICK"
    },
    "data": {
      "keyname": ''
    },
    // "to": "dn87btsM7Bg:APA91bHlYCHdJJNXVJcoWsq_jUtcumE8KJ3M2ir-UQweGSbeXouEFhHQMjfjn4SLgkiOY06Bar9Q0a4IWiZGUClZHJaK6nF7787eGJRjay6f3FNUIvsD7RdiIDXM_RibXb1rb8YTuQaG"
  };

  ModelBroadcast: any = [];
  ListUser: User[] = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private API: ApiService,
    public dialog: MatDialog,
    private router: Router,
    private toaster: ToastrService,
    private _fuseNavigationService: FuseNavigationService,
  ) {

  }

  async ngOnInit() {
    this.auth = await JSON.parse(localStorage.getItem("AUTH"));
    if (this.auth === null) {
      this.router.navigate(['/login']);
    }
    if (this.auth.level === 'vendor') {
      this._fuseNavigationService.updateNavigationItem('daftarvendor', {
        hidden: true
      });
      this._fuseNavigationService.updateNavigationItem('register', {
        hidden: true
      });
      this._fuseNavigationService.updateNavigationItem('travel', {
        hidden: true
      });
      this._fuseNavigationService.updateNavigationItem('slide', {
        hidden: true
      });
      this._fuseNavigationService.updateNavigationItem('panduan', {
        hidden: true
      });
      this._fuseNavigationService.updateNavigationItem('order', {
        hidden: true
      });
    } else {
      this._fuseNavigationService.updateNavigationItem('ordervendor', {
        hidden: true
      });
      this._fuseNavigationService.updateNavigationItem('mutawif', {
        hidden: true
      });
    }
    this.loaduser();
  }

  loaduser() {
    this.db.list(this.mainDB + 'clientToken').snapshotChanges().subscribe(result => {
      this.ListUser = [];
      result.forEach(res => {
        if (res.payload.val()['token'] !== undefined && res.payload.val()['token'] !== "") {
          this.clientToken.push(res.payload.val()['token']);
          this.ListUser.push(getListUser(res))
        }

      });
    });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  onSave() {
    this.loadSpinner();
    var currentUnixTime = Date.now();
    this.msg.notification["title"] = this.ModelBroadcast.title;
    this.msg.notification["body"] = this.ModelBroadcast.pesan;
    this.msg.data["keyname"] = currentUnixTime.toString();
    this.msg["registration_ids"] = this.clientToken;
    this.API.confirmToken(this.msg).subscribe(result => {
      this.closeSpinner();
      this.toaster.success("Pesan broadcast berhasil dikirim", "Pemberitahuan");
    });
    for (let i = 0; i < this.ListUser.length; i++) {
      this.db.database.ref('notifikasi/' + this.ListUser[i].uid + '/' + currentUnixTime + '/').set(
        {
          'pesan': this.ModelBroadcast.pesan,
          'title': this.ModelBroadcast.title,
          'status': 'success',
        }
      )
    }
  }
}

function getListUser(data: any): User {
  return {
    uid: data.key,
    token: data.payload.val()['token'],
  }
}

export interface User {
  uid: string;
  token: string;
}

import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
//import { DOCUMENT } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn, startWith, tap, windowWhen } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { FormControl, Validators, Form, NgForm, FormGroup } from '@angular/forms';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError, MatAutocompleteSelectedEvent } from '@angular/material';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
declare var jQuery: any;
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ApiService } from 'app/services/api.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { OrderService, ProdukData } from './service/order.service';
import { KurirModel, KurirService } from '../kurir-list/services/kurir.service';

@Component({
  selector: 'Order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class Order implements OnInit {

  displayedColumns = ['tgl', 'nama', 'total', 'item', 'status'];
  listmenu: ProdukData[] = [];
  listnotifpembayaran: notifPembayaran[] = [];
  dialogRef: any = [];
  dataSource: MatTableDataSource<ProdukData>;

  selectedMenu: String = 'pickup';
  tmpWait: ProdukData[] = [];
  tmpKemas: ProdukData[] = [];
  tmpTakeOrder: ProdukData[] = [];
  tmpPaid: ProdukData[] = [];
  tmpPickup: ProdukData[] = [];
  tmpOnPickup: ProdukData[] = [];
  tmpDone: ProdukData[] = [];
  tmpCancel: ProdukData[] = [];
  tmpDelivered: ProdukData[] = [];
  mainDB = environment.mainDb;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private orderService: OrderService,
    private af: AngularFireAuth,
    private storage: AngularFireStorage,
    private _fuseNavigationService: FuseNavigationService,
    private router: Router,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }


  async ngOnInit() {
    let auth = await localStorage.getItem("AUTH");
    if (auth === null) {
      this.router.navigate(['/login']);
    }
    this.loadpenjualan();
    this.loadnotif();
    this._fuseNavigationService.updateNavigationItem('order', {
      badge: {
        title: '',
        bg: '#2d323d',
        fg: '#2d323d'
      }
    });
  }


  loadpenjualan() {
    this.listmenu = [];
    this.tmpPickup = [];
    this.tmpOnPickup = [];
    this.tmpWait = [];
    this.tmpDone = [];
    this.tmpDelivered = [];
    this.tmpPaid = [];
    this.tmpTakeOrder = [];
    this.orderService.getItems().subscribe((val) => {
      this.listmenu = val;
      this.check('pickup');
    });

  }

  loadnotif() {
    this.listnotifpembayaran = [];
    this.db.list(this.mainDB + '/settings/notifikasi/')
      .snapshotChanges()
      .subscribe(pembayaran => {
        pembayaran.forEach(items => {
          this.listnotifpembayaran.push(getListNotifPembayaran(items.payload.val()['judul'], items.payload.val()['isi']));
        });
      });
  }

  check(status) {
    /**
     * waiting new order
     * kemas produk slesai di kemas
     * pickup request produk di jemput oleh seller
     * onpickup produk dalam proses penjemputan
     * takingorder produk dalam proses pengantaran ke pembeli
     * delivered produk sudah slesai di antarkan
     * done tranksaksi selesai
     */

    this.selectedMenu = status
    this.dataSource = new MatTableDataSource([]);
    switch (status) {
      case 'waiting':
        this.onnew();
        break;
      case 'kemas':
        this.onkemas();
        break;
      case 'pickup':
        this.pickup();
        break;
      case 'onpickup':
        this.onpickup();
        break;
      case 'takingorder':
        this.otw();
        break;
      case 'delivered':
        this.onDelivered()
        break;
      case 'done':
        this.onFinish()
        break;
      case 'cancel':
        this.onCancel()
        break;
      default:
        this.onnew()
        break;
    }

    // this.tmpNew = [];
    // this.listmenu.forEach(data => {
    //   if (data.status === 'new') {
    //     this.tmpNew.push(data);
    //     setTimeout(() => {
    //       this.dataSource = new MatTableDataSource(this.tmpNew);
    //       this.dataSource.paginator = this.paginator;
    //       this.dataSource.sort = this.sort;
    //     }, 500);
    //   }
    // });
  }
  onnew() {
    this.tmpWait = [];
    this.listmenu.forEach(data => {
      if (data.status === 'waiting') {
        this.tmpWait.push(data);
          this.dataSource = new MatTableDataSource(this.tmpWait);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      }
    });
  }

  pickup() {
    this.tmpPickup = [];
    this.listmenu.forEach(data => {
      if (data.status === 'pickup') {
        this.tmpPickup.push(data);
          this.dataSource = new MatTableDataSource(this.tmpPickup);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      }
    });
  }

  onpickup() {
    this.tmpOnPickup = [];
    this.listmenu.forEach(data => {
      if (data.status === 'onpickup') {
        this.tmpOnPickup.push(data);
          this.dataSource = new MatTableDataSource(this.tmpOnPickup);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      }
    });
  }

  otw() {
    this.tmpTakeOrder = [];
    this.listmenu.forEach(data => {
      if (data.status === 'takingorder') {
        this.tmpTakeOrder.push(data);
          this.dataSource = new MatTableDataSource(this.tmpTakeOrder);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      }
    });
  }

  onkemas() {
    this.tmpKemas = [];
    this.listmenu.forEach(data => {
      if (data.status === 'kemas') {
        this.tmpKemas.push(data);
          this.dataSource = new MatTableDataSource(this.tmpKemas);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      }
    });
  }

  onDelivered() {
    this.tmpDelivered = [];
    this.listmenu.forEach(data => {
      if (data.status === 'delivered') {
        this.tmpDelivered.push(data);
          this.dataSource = new MatTableDataSource(this.tmpDelivered);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      }
    });
  }

  onCancel() {
    this.tmpCancel = [];
    this.listmenu.forEach(data => {
      if (data.status === 'cancel') {
        this.tmpCancel.push(data);
          this.dataSource = new MatTableDataSource(this.tmpCancel);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      }
    });
  }

  onFinish() {
    this.tmpDone = [];
    this.listmenu.forEach(data => {
      if (data.status === 'done') {
        this.tmpDone.push(data);
        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.tmpDone);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 500);
      }
    });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }


  confirmDialog(ex): void {
    this.dialogRef = this.dialog.open(dialogConfirm, {
      width: '95%',
      height: '90%',
      data: { penjualan: ex, notif: this.listnotifpembayaran },
    });

    this.dialogRef.afterClosed().subscribe(result => {

    });
  }

  cetak(): void {
    this.dialogRef = this.dialog.open(printDialog, {
      width: '95%',
      height: '90%',
      data: {
        penjualan: this.listmenu,
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {

    });
  }
}

@Component({
  selector: 'print-dialog',
  templateUrl: './printdialog.html',
  styleUrls: ['./order.component.scss']
})
export class printDialog {

  constructor(
    public dialogRef: MatDialogRef<printDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,) { }

  ngOnInit(): void {
    console.log(this.data);
  }
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirmdialog/confirm-dialog.html',
  styleUrls: ['confirmdialog/confirm.component.scss']
})
export class dialogConfirm {
  cutiForm: FormGroup;
  konfirmasi: any = [];
  chart: any = [];
  notif: any = [];
  currentUnixTime = Date.now();
  tokenFcm: String;
  dialogKurir: any = [];
  UrlToken = 'https://fcm.googleapis.com/fcm/send';
  mainDB = environment.mainDb;
  statusTmp: any;

  DataKurir: any = [];
  finaldata: any = [];
  sale: any = [];

  filteredOptions: Observable<ikurir[]>;
  options: ikurir[] = [];
  value2: any;
  ModelCuti: any = [];
  tmpdata: any[] = [];
  uid: any;
  chartNumber: number;
  constructor(
    http: HttpClient,
    private API: ApiService,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<dialogConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  msg = {
    "notification": {
      "image": "https://firebasestorage.googleapis.com/v0/b/royyan-48da2.appspot.com/o/mbb.png?alt=media&token=4bbc5ed4-bbc8-45a3-8b6a-9c0d76a21511",
      "color": "#990000",
      "click_action": "FLUTTER_NOTIFICATION_CLICK"
    },
    "data": {
      'key': 'chat',
      "id": this.data.penjualan.id
    },
    // "to": "dn87btsM7Bg:APA91bHlYCHdJJNXVJcoWsq_jUtcumE8KJ3M2ir-UQweGSbeXouEFhHQMjfjn4SLgkiOY06Bar9Q0a4IWiZGUClZHJaK6nF7787eGJRjay6f3FNUIvsD7RdiIDXM_RibXb1rb8YTuQaG"
  };

  msgkurir = {
    "notification": {
      // "image": "https://firebasestorage.googleapis.com/v0/b/pilihmanasports.appspot.com/o/icons%2FkumpoolDriver.png?alt=media&token=364b1e03-d40c-4d3f-86ef-d6b737f5eef7",
      "color": "#990000",
      "click_action": "FLUTTER_NOTIFICATION_CLICK"
    },
    "data": {

    },

    // "to": "dn87btsM7Bg:APA91bHlYCHdJJNXVJcoWsq_jUtcumE8KJ3M2ir-UQweGSbeXouEFhHQMjfjn4SLgkiOY06Bar9Q0a4IWiZGUClZHJaK6nF7787eGJRjay6f3FNUIvsD7RdiIDXM_RibXb1rb8YTuQaG"
  };

  ngOnInit(): void {
    if (this.data.penjualan.kurir.vendorKurir === "") {
      this.DataKurir = [];
    } else {
      this.DataKurir.push(this.data.penjualan.kurir);
    }

    this.chart = this.data.penjualan.chart;
    this.konfirmasi = this.data;
    this.notif = this.data.notif;

    var idInduk = this.data.penjualan.idInduk;
    var id = this.data.penjualan.id;
    var jlh = this.data.penjualan.chart;
    var title = this.notif[0].judul;
    var isi = this.notif[0].isi;
    var unixTime = Date.now();


    this.tokenFcm = this.data.penjualan.tokenFcm;

    // this.cutiForm = new FormGroup({
    //   nip: new FormControl(''),
    // });
  }




  LihatGbr(e) {
    var win = window.open(e, '_blank');
    win.focus();
  }

  onNew() {
    var idInduk = this.data.penjualan.idInduk;
    var id = this.data.penjualan.id;
    var jlh = this.data.penjualan.chart;
    var title = this.notif[0].judul;
    var isi = this.notif[0].isi;
    this.msg["to"] = this.tokenFcm;
    this.msg.notification["title"] = title;
    this.msg.notification["body"] = isi;
    var unixTime = Date.now();
    this.db.database.ref(this.mainDB + '/Penjualan/' + idInduk + '/' + id + '/status').set('waiting');

    this.API.confirmToken(this.msg).subscribe(result => {
      console.log(result);
    });

    this.db.database.ref(this.mainDB + '/notifikasi/' + idInduk + '/' + this.currentUnixTime).set({
      'title': title,
      'isi': isi,
      'kodechart': id,
      'tanggal': Date.now().valueOf(),
    });
    this.dialogRef.close();
  }

  onWaiting() {
    if (this.data.penjualan.statusbayar.toLowerCase() === "transfer") {
      var idInduk = this.data.penjualan.idInduk;
      var id = this.data.penjualan.id;
      var jlh = this.data.penjualan.chart;
      var title = this.notif[1].judul;
      var isi = this.notif[1].isi;
      this.msg["to"] = this.tokenFcm;
      this.msg.notification["title"] = title;
      this.msg.notification["body"] = isi;
      var unixTime = Date.now();
      this.db.database.ref(this.mainDB + '/Penjualan/' + idInduk + '/' + id + '/status').set('paid');

      this.API.confirmToken(this.msg).subscribe(result => {
        console.log(result);
      });
      this.db.database.ref(this.mainDB + '/notifikasi/' + idInduk + '/' + this.currentUnixTime).set({
        'title': title,
        'isi': isi,
        'kodechart': id,
        'tanggal': Date.now().valueOf(),
      });
      this.dialogRef.close();
    } else {
      var idInduk = this.data.penjualan.idInduk;
      var id = this.data.penjualan.id;
      var jlh = this.data.penjualan.chart;
      var title = this.notif[5].judul;
      var isi = this.notif[5].isi;
      this.msg["to"] = this.tokenFcm;
      this.msg.notification["title"] = title;
      this.msg.notification["body"] = isi;
      var unixTime = Date.now();
      this.db.database.ref(this.mainDB + '/Penjualan/' + idInduk + '/' + id + '/status').set('paid');

      this.API.confirmToken(this.msg).subscribe(result => {
        console.log(result);
      });
      this.db.database.ref(this.mainDB + '/notifikasi/' + idInduk + '/' + this.currentUnixTime).set({
        'title': title,
        'isi': isi,
        'kodechart': id,
        'tanggal': Date.now().valueOf(),
      });
      this.dialogRef.close();
    }
  }

  wa() {
    window.open("https://wa.me/" + this.DataKurir[0].kontak + "?text=Nama Penerima :" + this.data.penjualan.pengiriman.penerima + ",%0aAlamat Penerima :" + this.data.penjualan.pengiriman.alamat + "," + this.data.penjualan.pengiriman.kecamatan + "-" + this.data.penjualan.pengiriman.kelurahan + ",%0aKodepos :" + this.data.penjualan.pengiriman.kodePos + ",%0aKontak :" + this.data.penjualan.pengiriman.kontak + ",%0aMBB Shop%0a%0a" + "http://www.google.com/maps/place/" + this.data.penjualan.pengiriman.lat + "," + this.data.penjualan.pengiriman.lng, "_blank");
    this.dialogRef.close();
  }

  async simpanKurir() {
    var idInduk = this.data.penjualan.id;
    var kurirUid = this.data.penjualan.kurir.uid;
    var title = this.notif[2].judul;
    var isi = this.notif[2].isi;
    let kode = this.currentUnixTime;

    //let pesanNotif = this.notif.filter((item) => item.status === 'pickup')
    this.msgkurir["to"] = this.data.penjualan.kurir.token;
    this.msgkurir.notification["title"] = 'Order Baru';
    this.msgkurir.notification["body"] = 'Hai ada order baru nih, silahkah selesaikan orderan !';

    this.msgkurir.data["key"] = 'order';
    this.msgkurir.data["id"] = kode;
    this.db.database.ref(this.mainDB + '/Penjualan/' + idInduk + '/'+'kurirstatus').set('waiting');

    this.API.confirmToken(this.msgkurir).subscribe((val)=>{
      console.log(val)
    });


    this.db.database.ref(this.mainDB + '/TransaksiKurir/' + idInduk).set({
      'uid':kurirUid,
      'kodePenjualan': idInduk,
      'status':'waiting',
      'tanggal': Date.now().valueOf(),
    });

    this.db.database.ref(this.mainDB + '/Penjualan/' + idInduk + '/kurir/').set({
      kurirUId:this.data.penjualan.kurir.uid,
      biayaKirim: this.data.penjualan.kurir.biayaKirim,
      estimasi: this.data.penjualan.kurir.estimasi,
      foto: this.data.penjualan.kurir.foto,
      jarak: this.data.penjualan.kurir.jarak,
      kontak: this.data.penjualan.kurir.kontak,
      nama: this.data.penjualan.kurir.nama,
      noResi: this.data.penjualan.kurir.noResi,
      token: this.data.penjualan.kurir.token,
      vendorKurir: this.data.penjualan.kurir.vendorKurir
    });
    this.dialogRef.close();
  }

  onDone() {
    // alert('Terima kasih telah melakukan pembayaran');
    var idInduk = this.data.penjualan.idInduk;
    var id = this.data.penjualan.id;
    var jlh = this.data.penjualan.chart;
    var title = this.notif[3].judul;
    var isi = this.notif[3].isi;
    this.msg["to"] = this.tokenFcm;
    this.msg.notification["title"] = title;
    this.msg.notification["body"] = isi;

    this.db.database.ref(this.mainDB + '/Penjualan/' + idInduk + '/' + id + '/status').set('confirm');

    this.API.confirmToken(this.msg).subscribe(result => {
      console.log(result);
    });

    this.db.database.ref(this.mainDB + '/notifikasi/' + idInduk + '/' + this.currentUnixTime).set({
      'title': title,
      'isi': isi,
      'kodechart': id,
      'tanggal': Date.now().valueOf(),
    });
    // this.wa();
    this.dialogRef.close();
  }

  onDelivered() {
    // alert('Terima kasih telah melakukan pembayaran');
    var idInduk = this.data.penjualan.idInduk;
    var id = this.data.penjualan.id;
    var jlh = this.data.penjualan.chart;
    var title = this.notif[4].judul;
    var isi = this.notif[4].isi;
    this.msg["to"] = this.tokenFcm;
    this.msg.notification["title"] = title;
    this.msg.notification["body"] = isi;

    this.db.database.ref(this.mainDB + '/Penjualan/' + idInduk + '/' + id + '/status').set('finish');

    this.API.confirmToken(this.msg).subscribe(result => {
    });

    // this.msgKurir["to"] = 'eK_FQucuAyU:APA91bFrpWIz0d7sJoVY2omESL4jcOnVtZwoN9ufJtzFz_PjPWnx4X4RqLpmnZ0J8B-Ta4XdDWHjfITIw5sXixE7yXRF4XUcbsx0yzDtUniRprFJkfUkDwV3hegD2oZr85IgTp_dkKYb';
    // this.msgKurir.notification["title"] = 'Pesanan Baru';
    // this.msgKurir.notification["body"] = 'Pesanan baru dari '+ this.data.penjualan.nama;

    // this.API.confirmKurir(this.msgKurir).subscribe(result => {
    //   console.log(result);
    // });

    // this.chart.forEach(data => {
    //   this.db.database.ref(this.mainDB + '/sale/' + data.penjualan.idProduct + '/' + this.currentUnixTime).set({
    //     'jlh': data.jlh,
    //   });
    // });

    this.db.database.ref(this.mainDB + '/notifikasi/' + idInduk + '/' + this.currentUnixTime).set({
      'title': title,
      'isi': isi,
      'kodechart': id,
      'tanggal': Date.now().valueOf(),
    });
  }

  onDismiss() {
    this.dialogRef.close();
  }

  onCetak() {
    const dialogRef = this.dialog.open(printStruckDialog, {
      width: '80%',
      height: '70%',
      data: {
        penjualan: this.data.penjualan,
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {

    });
  }

  findIndexToUpdate(newItem) {
    return newItem.id === this;
  }

  Kurir(item, index) {
    this.chartNumber = index;
    console.log(this.data.penjualan)
    if(this.data.penjualan.kurirstatus ==='waiting'){
      this.data.penjualan.kurirstatus='change';
    }
    if (this.DataKurir.length === 0) {
      this.dialogKurir = this.dialog.open(dialogKurir, {
        width: '40%',
        height: '70%',
        data: {
          action: 'new',
          kurir: item,
        }
      });
    } else {
      this.dialogKurir = this.dialog.open(dialogKurir, {
        width: '40%',
        height: '70%',
        data: {
          action: 'edit',
          Kurir: this.data.penjualan.kurir,
        }
      });
    }

    this.dialogKurir.afterClosed().subscribe(result => {
      if(result.action !=='close'){
      this.data.penjualan.kurir.estimasi = result.data.estimasi;
      this.data.penjualan.kurir.kontak = result.data.kontak;
      this.data.penjualan.kurir.nama = result.data.nama;
      this.data.penjualan.kurir.noResi = result.data.noResi;
      this.data.penjualan.kurir.foto = result.data.foto;
      this.data.penjualan.kurir.token = result.data.token;
      this.data.penjualan.kurir.uid= result.data.kurirUid;
      }
    });
  }

  
}

@Component({
  selector: 'struck-dialog',
  templateUrl: './printStruckdialog.html',
  styleUrls: ['kurirdialog/kurir.component.scss']
})
export class printStruckDialog {
  modelKurir: any = [];
  title: string = '';
  tombol: string = '';

  constructor(
    http: HttpClient,
    private API: ApiService,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<dialogConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  Simpan() {
    this.dialogRef.close({
      'action': this.data.action,
      'data': this.modelKurir,
    });
  }

  Tutup() {
    this.dialogRef.close({
      'action': 'close',
    })
  }
}

@Component({
  selector: 'kurir-dialog',
  templateUrl: 'kurirdialog/kurir-dialog.html',
  styleUrls: ['kurirdialog/kurir.component.scss']
})
export class dialogKurir {
  kurirform: FormGroup;
  modelKurir: any = [];
  title: string = '';
  tombol: string = '';

  filteredOptions: Observable<ikurir[]>;
  options: ikurir[] = [];
  value2: any;
  ModelCuti: any = [];
  tmpdata: any[] = [];
  pil: any;
  uid: any;
  mainDB = environment.mainDb;
  constructor(
    http: HttpClient,
    private API: ApiService,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    private kurirService: KurirService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<dialogConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.getKurir();
    if (this.data.action === 'new') {
      this.modelKurir = this.data.kurir;
      // console.log(this.modelKurir);
      this.title = '';
      this.tombol = 'Simpan';
    } else {
      this.modelKurir = this.data.kurir;
      this.title = 'Edit Detail';
      this.tombol = 'Edit';
    }

    this.kurirform = new FormGroup({
      vendorKurir: new FormControl('', [Validators.required]),
      nama: new FormControl('', [Validators.required]),
      estimasi: new FormControl('', [Validators.required]),
      satuan: new FormControl(''),
      kontak: new FormControl('', [Validators.required]),
      noResi: new FormControl(''),
      ongkir: new FormControl('', [Validators.required]),
    });

    this.filteredOptions = this.kurirform.controls['nama'].valueChanges
      .pipe(
        startWith({} as ikurir),
        map(user => user && typeof user === 'object' ? user.nama : user),
        map((nama: string) => nama ? this.filter(nama) : this.options.slice())
      );
  }

  async getKurir() {
    this.kurirService.getItems().subscribe((values) => {
      this.options = [];
      values.forEach(e => {
        if (e.active === true) {
          this.options.push(getListkurir(e));
        }
      });
    });
  }

  displayFn(user: ikurir): string {
    return user ? user.nama : '';
  }

  _filter(nama: string): ikurir[] {
    
    return this.options.filter(option =>
      option.nama.toLowerCase().indexOf(nama.toLowerCase()) === 0);
  }

  private filter(value: string): ikurir[] {
    const filterValue = value.toLowerCase();
    const results = this.options.filter(option => option.nama.toLowerCase().includes(filterValue));

    return results;
  }

  _allowSelection(option: string): { [className: string]: boolean } {
    return {
      'no-data': option === 'No data',
    };
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.tmpdata = [];
    this.uid = '';
    this.pil = event.option.value;
    this.kurirform.controls['kontak'].setValue(this.pil.kontak);
  }

  Simpan() {
    this.dialogRef.close({
      'action': this.data.action,
      'data': {
        //'uid': this.modelKurir.id.id,
        //'biayaKirim': this.modelKurir.biayaKirim,
        'estimasi': this.kurirform.controls['estimasi'].value + ' ' + this.kurirform.controls['satuan'].value,
        'nama': this.pil.nama,
        //'satuan': this.modelKurir.satuan,
        //'jarak': this.modelKurir.jarak,
        'kontak': this.kurirform.controls['kontak'].value,
        'noResi': this.kurirform.controls['noResi'].value,
        //'vendorKurir': this.modelKurir.id,
        //'ongkir': this.modelKurir.ongkir,
        'token': this.pil.token,
        'foto': this.pil.photo,
        'kurirUid': this.pil.id,
      },
    });
  }

  Tutup() {
    this.dialogRef.close({
      'action': 'close',
    })
  }
}


export interface notifPembayaran {
  judul: string;
  isi: string;
}


function getListNotifPembayaran(judul: string, isi: string): notifPembayaran {
  return {
    judul: judul,
    isi: isi,
  };
}
function getListkurir(data: KurirModel): ikurir {
  return {
    id: data.id,
    nama: data.nama,
    kontak: data.phone,
    token: data.pushToken,
    photo: data.photoUrl,
  }
}
export interface ikurir {
  id: string;
  nama: string;
  kontak: string;
  token: string;
  photo: string;
}
export const COMPONENT_LIST = [
  Order,
  dialogConfirm,
  dialogKurir,
  printDialog,
  printStruckDialog
]

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from '../service/api.service';
import { SubCategory } from '../service/model';
@Component({
  selector: 'app-add-blog',
  templateUrl: './add-blog.component.html',
  styleUrls: ['./add-blog.component.scss']
})
export class AddBlogComponent implements OnInit {
  myControl = new FormControl();
  ModelPanduan: any = [];
  dialogRef: any;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  title: string;
  ListCategory: SubCategory[] = [];
  filteredCat: Observable<SubCategory[]>;
  constructor(
    private dbService: ApiService,
    public dialog: MatDialogRef<AddBlogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.filteredCat = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this._filterCat(state) : this.ListCategory.slice())
      );
  }

  async ngOnInit() {
    if (this.data.action == 'edit') {
      this.title = "Edit blog" + this.data.row.judul;
      this.ModelPanduan = this.data.row;
    } else {
      this.title = "Buat blog baru";
    }
    await this.dbService.getCat().subscribe((res) => {
      this.ListCategory = res;
    });

  }
  displayFn(data?: SubCategory): string | undefined {
    return data ? data.nama : undefined;
  }

  private _filterCat(value: string): SubCategory[] {
    const filterValue = value.toLowerCase();
    return this.ListCategory.filter(cat => cat.nama.toLowerCase().indexOf(filterValue) === 0);
  }

  async onSave() {
    this.spinnerWithoutBackdrop = true;
    if (this.data.action === 'new') {
      await this.dbService.simpanBlog({
        isi: this.ModelPanduan.isi,
        kategori: this.myControl.value,
        judul: this.ModelPanduan.judul,
      });
      this.spinnerWithoutBackdrop = false;
      this.dialog.close();
    } else {
      await this.dbService.updateBlog({
        id: this.data.row.id,
        isi: this.ModelPanduan.isi,
        kategori: this.myControl.value,
        judul: this.ModelPanduan.judul,
      })
      this.spinnerWithoutBackdrop = false;
      this.dialog.close();
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'environments/environment';
import { FaqModel, FaqService } from './service/faq.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';



ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'bulletedList',
      'numberedList',
      'blockQuote',
      'undo',
      'redo'
    ],
    language: 'en'
  },

  // This value must be kept in sync with the language defined in webpack.config.js.
  language: 'en'
};

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  public privForm = new FormGroup({
    description: new FormControl('<p>A <b>really</b> nice fellow.</p>')
  });
  faqList: any = [];
  mainDB = environment.mainDb;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  constructor(
    private ApiService: FaqService,
    public dialog: MatDialog,
    public toast: ToastrService,
    //public dialogRef: MatDialogRef<DialogTambahHelp>
    ) {
  }

  ngOnInit() {
    this.getItems();
  }

  ngOnDestroy() {
    //this.ApiService.destroy();
    this.faqList = [];
  }


  getItems() {
    this.ApiService.getItems().subscribe((val) => {
      this.faqList = val;
    })
  }

  tambah() {
    this.dialog.open(DialogTambahHelp, {
      panelClass: 'dialog',
      hasBackdrop: true,
      data: { 'action': 'new' },
      // height: '70%',
    });

  }

  async onRemove(e) {
    var r = confirm("Are you sure delete " + e.Nama + " ?");
    if (r == true) {
      this.spinnerWithoutBackdrop = true;
      await this.ApiService.deleteItems(e.key).then((a) => {
        if (a) {
          this.spinnerWithoutBackdrop = false;
          //this.dialogRef.close; a
        } else {
          this.spinnerWithoutBackdrop = false;
          this.toast.error("Save fail, please check internet connection", "Error");
        }
      });
    }
  }

  onEdit(data) {
    this.dialog.open(DialogTambahHelp, {
      panelClass: 'dialog',
      hasBackdrop: true,
      data: { 'action': 'edit', 'data': data },
      width: '40%',
      // height: '70%',

    });
  }
}


@Component({
  selector: 'edithelpdialog',
  templateUrl: './add/add.component.html',
  styleUrls: ['./faq.component.scss']
})
export class DialogTambahHelp {
  public Editor = ClassicEditor;
  imageChangedEvent: any = '';
  gambar: any;
  file: any;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDB = environment.mainDb;
  public faqForm = new FormGroup({
    description: new FormControl('', Validators.required),
    title: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-z0-9.,?: &-]*$")]),
  });
  constructor(
    private ApiService: FaqService,
    public toast: ToastrService,
    public dialogRef: MatDialogRef<DialogTambahHelp>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data.action === "edit") {
      this.faqForm.patchValue({ "description": this.data.data.Materi, "title": this.data.data.Nama })
    }
  }
  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  onSave() {
    this.loadSpinner();
    var data: FaqModel = { Nama: this.faqForm.get("title").value, Materi: this.faqForm.get("description").value };
    if (this.data.action === "new") {
      this.ApiService.postItems(data).then((a) => {
        if (a === true) {
          this.dialogRef.close();
          this.closeSpinner();
        } else {
          this.closeSpinner();
          this.toast.error("Save fail, please check internet connection", "Error");
        }
      })
    } else {
      var data: FaqModel = { id: this.data.data.key, Nama: this.faqForm.get("title").value, Materi: this.faqForm.get("description").value };
      this.ApiService.updateItems(data).then((a) => {
        if (a === true) {
          this.dialogRef.close();
          this.closeSpinner();
        } else {
          this.closeSpinner();
          this.toast.error("Save fail, please check internet connection", "Error");
        }
      })
    }

  }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddcategoryComponent } from './addcategory/addcategory.component';
import { CategoryModel } from './model.component';
import { ServicesService } from './services.service';
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  private Unsubscribe = new Subject();
  listproduk: CategoryModel[] = [];
  filteredOptions: Observable<any>;
  dataSource: MatTableDataSource<CategoryModel>;
  mainDB = environment.mainDb;
  croppedImage: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(
    private db: AngularFireDatabase,
    public dbService: ServicesService,
    public dialog: MatDialog,
    //public dialogRef: MatDialogRef<AddcategoryComponent>,
  ) { }

  ngOnInit() {
    this.dbService.getItems()
      .pipe(
        takeUntil(this.Unsubscribe)
      ).subscribe((supplierAll) => {
        this.listproduk = supplierAll
        this.dataSource = new MatTableDataSource(this.listproduk)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      })
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async delRecord(item) {
    var r = confirm("Yakin ingin menghapus " + item.nama + " ?");
    if (r === true) {
      this.spinnerWithoutBackdrop = true;
      this.dbService.delete(item).then((res) => {
        this.spinnerWithoutBackdrop = false;
      }).catch((er) => {
        this.spinnerWithoutBackdrop = false;
      });
    }
  }
  getRecord(item) {
    const dialogRef = this.dialog.open(AddcategoryComponent, {
      panelClass: 'app-addcategory',
      hasBackdrop: true,
      // width: '40%',
      // height: '90%',
      // maxWidth: '50vw',
      // maxHeight: '70vh',
      data: {
        action: 'edit',
        row: item
      }
    });
  }
  addProduct() {
    const dialogRef = this.dialog.open(AddcategoryComponent, {
      panelClass: 'app-addcategory',
      hasBackdrop: true,
      // width: '40%',
      // height: '90%',
      // maxWidth: '50vw',
      // maxHeight: '70vh',
      data: {
        action: 'new',
      }
    });
    dialogRef.afterClosed().subscribe((res) => {

    });
  }
  publish(item) {
    this.db.database.ref(this.mainDB + 'categoryDisplay/' + item.id + '/publish').set(true);
  }

  unpublish(item) {
    this.db.database.ref(this.mainDB + 'categoryDisplay/' + item.id + '/publish').set(false);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { ApiServiceService } from '../api-service.service';
import { SubCategory } from './model';

@Component({
  selector: 'app-addsub',
  templateUrl: './addsub.component.html',
  styleUrls: ['./addsub.component.scss']
})
export class AddsubComponent implements OnInit {
  ListCat: SubCategory[] = [];
  private Unsubscribe = new Subject();
  categoryForm: FormGroup;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  constructor(
    public dbService: ApiServiceService,
    public dialogRef: MatDialogRef<AddsubComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.categoryForm = new FormGroup({
      nama: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.dbService.getItems(this.data.row.id)
      .pipe(
        takeUntil(this.Unsubscribe)
      ).subscribe((result) => {
        this.ListCat = result;
      })
  }
  RemoveMore(index) {
    this.ListCat.splice(index, 1);
    // console.log(this.ListImageEdit);
  }
  addCategory() {
    this.ListCat.push({ nama: this.categoryForm.get('nama').value })
    this.categoryForm.reset();
  }
  saveCategory() {
    this.spinnerWithoutBackdrop = true;
    this.dbService.simpanCat(this.ListCat, this.data.row.id).then((res) => {
      this.spinnerWithoutBackdrop = false;
    }).catch((err) => {
      this.spinnerWithoutBackdrop = false;
    })
  }


}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { TagContent } from '../model.component';
import { ServicesService } from '../services.service';
import { COMMA, ENTER, } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-addcategory',
  templateUrl: './addcategory.component.html',
  styleUrls: ['./addcategory.component.scss'],
  providers: [ServicesService],
})
export class AddcategoryComponent implements OnInit {
  categoryForm: FormGroup;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  tagContent: TagContent[] = [];
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  gambar: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA,];
  constructor(
    public dbService: ServicesService,
    public dialogRef: MatDialogRef<AddcategoryComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.categoryForm = new FormGroup({
      nama: new FormControl('', Validators.required),
    });
  }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  ngOnInit() {
    if (this.data.action === 'edit') {
      this.tagContent = this.data.row.tag;
      this.categoryForm.patchValue({
        nama: this.data.row.nama,
      })
      this.gambar = this.data.row.image
    }
  }


  getFileAttachment(event: any): void {
    this.imageChangedEvent = event;
  }

  openfile() {
    jQuery('#imgupload').trigger('click');
  }

  remove(data: TagContent): void {
    const index = this.tagContent.indexOf(data);
    if (index >= 0) {
      this.tagContent.splice(index, 1);
    }
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.tagContent.push({ namaTag: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this.gambar = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  saveCategory() {
    const currentUnixTime = Date.now();
    let filename = this.categoryForm.get('nama').value + '_' + currentUnixTime + '.webp';
    let filePath = 'display' + '/' + filename;
    if (this.data.action === 'edit' && this.croppedImage !== '') {
      this.spinnerWithoutBackdrop = true;
      this.dbService.putKasus({
        nama: this.categoryForm.get('nama').value,
        id: this.data.row.id,
        publish: this.data.row.publish,
        filename: this.data.row.filename !== '' ? this.data.row.filename : filename,
      }, this.croppedImage, this.tagContent).then((res) => {
        console.log(res)
        if (res) {
          this.dialogRef.close('save');
          this.spinnerWithoutBackdrop = false;
        } else {
          this.spinnerWithoutBackdrop = false;
        }
      }).catch((err) => {
        this.spinnerWithoutBackdrop = false;
      });
    }
    if (this.data.action === 'edit' && this.croppedImage === '') {
      this.spinnerWithoutBackdrop = true;
      this.dbService.putKasusNo({
        nama: this.categoryForm.get('nama').value,
        id: this.data.row.id,
        filename: this.data.row.filename,
        image: this.data.row.image,
        tag: this.tagContent,
        publish: this.data.row.publish,
      }).then((res) => {
        if (res) {
          this.dialogRef.close('save');
          this.spinnerWithoutBackdrop = false;
        } else {
          this.spinnerWithoutBackdrop = false;
        }
      }).catch((err) => {
        this.spinnerWithoutBackdrop = false;
      });;
    }
    if (this.data.action === 'new') {
      if (this.croppedImage === '') {
        alert('Gambar belum di isi');
        return
      }
      this.spinnerWithoutBackdrop = true;
      this.dbService.postKasus({
        nama: this.categoryForm.get('nama').value,
        publish: true,
      }, filePath, this.croppedImage, filename, this.tagContent).then((res) => {
        if (res) {
          this.dialogRef.close('save');
          this.spinnerWithoutBackdrop = false;
        } else {
          this.spinnerWithoutBackdrop = false;
        }
      }).catch((err) => {
        this.spinnerWithoutBackdrop = false;
      });;
    }
  }
}
